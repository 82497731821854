import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const CustomAutocomplete = (props) => {
	const [active, setActive] = useState(0);
	const [filtered, setFiltered] = useState([]);
	const [isShow, setIsShow] = useState(false);
	const [input, setInput] = useState("");

	const onChange = (e) => {
		const { suggestions } = props;

		const input = e.currentTarget.value;

		let newFilteredSuggestions = [];
		if (input !== "") {
			newFilteredSuggestions = suggestions.filter((suggested) => {
				//questo è il filtro
				return Object.keys(suggested).some((prop) => {
					//uso le key

					if (props.fields.indexOf(prop) != -1) {
						//Controllo soltanto le proprietà che voglio filtrare

						return (
							String(suggested[prop])
								.toLowerCase()
								.indexOf(input.toLowerCase()) != -1
						); //ora trovo il dato con quella key
					} else {
						return false;
					}
				});
			});
		} else {
			newFilteredSuggestions = suggestions;
		}
		setFiltered(newFilteredSuggestions);
		setActive(0);
		setIsShow(true);
		setInput(e.currentTarget.value);
	};

	useEffect(() => {
		const fetchData = async () => {
			if (props.value !== null && props.value !== undefined) {
				let el = props.suggestions.filter((el) => {
					return el[props.keyField] === props.value;
				})[0];
				if (el) {
					let currentVal = props.fields.map((f) => el[f]);
					setInput(currentVal.join(" "));
					//console.log(currentVal.join(" "));
				}
			} else {
				setInput("");
			}
			await setFiltered(props.suggestions);
		};
		fetchData();
	}, [props.suggestions, props.value]); //li aggiorna quando cambiano valore

	const onClick = async (e) => {
		let value = e.currentTarget.value;
		setActive(0);
		setFiltered([]);
		setIsShow(false);
		setInput(e.currentTarget.innerText);
		await props.onChange(value);
		if (props.loadData) {
			props.loadData(value);
		}
	};
	const onKeyDown = (e) => {
		if (e.keyCode === 13) {
			// enter key
			setActive(0);
			setIsShow(false);
			setInput(filtered[active]);
		} else if (e.keyCode === 38) {
			// up arrow
			return active === 0 ? null : setActive(active - 1);
		} else if (e.keyCode === 40) {
			// down arrow
			return active - 1 === filtered.length ? null : setActive(active + 1);
		}
	};

	//LISTA SUGGESTIONS
	const renderAutocomplete = () => {
		if (isShow) {
			if (filtered.length) {
				return (
					<>
						<ul className=" bg-white  border-slate-300 text-gray-600 text-base font-medium w-full overflow-auto max-h-[300px] absolute z-10">
							{filtered.map((suggestion, index) => {
								let className =
									"block px-4 py-2 border-b border-slate-300 w-full hover:bg-gray-100 hover:text-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-700 focus:text-primary-700 cursor-pointer";
								if (index === active) {
									className += " active";
								}
								return (
									<li
										className={className}
										key={suggestion[props.keyField]}
										onClick={onClick}
										value={suggestion[props.keyField]}
									>
										{props.fields.map(
											(f) =>
												suggestion[f].charAt(0).toUpperCase() +
												suggestion[f].toLowerCase().slice(1) +
												" "
										)}
									</li>
								);
							})}
						</ul>
					</>
				);
			} else {
				return (
					<div className="bg-white border-slate-300 text-gray-900 text-sm font-medium w-full overflow-auto max-h-[300px] absolute  z-50">
						<li className="block px-4 py-2 border-b border-slate-300 w-full hover:bg-gray-100 hover:text-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-700 focus:text-primary-700 cursor-pointer">
							Nessun risultato
						</li>
					</div>
				);
			}
		}
		return <></>;
	};

	window.addEventListener("click", function (e) {
		if (document.getElementById(props.id)?.contains(e.target)) {
			setActive(0);
			setIsShow(true);
		} else {
			setActive(0);
			setIsShow(false);
		}
	});

	return (
		<div
			className="relative w-full" // l'absolute figlio si riferisce il relative padre
			onClick={() => setIsShow(false)}
		>
			<span className="label-text capitalize left-2 -top-2">{props.label}</span>
			<label className="relative block " onClick={(e) => e.stopPropagation()}>
				<input
					autoComplete="off"
					className={props.error ? "input-text-error" : "input-text"}
					type="text"
					placeholder={input !== "" ? input : props.placeholder}
					onClick={onChange}
					onChange={onChange}
					onKeyDown={onKeyDown}
					value={input}
					id={props.id}
					disabled={props.disabled}
				/>
				<div
					className="absolute top-2/4 right-3 grid h-5 w-5 -translate-y-2/4 place-items-center text-white cursor-pointer"
					onClick={() => {
						setInput("");
					}}
				>
					<AiOutlineClose className="button-icon hover:scale-125 transition-all" />
				</div>
			</label>
			{renderAutocomplete()}
		</div>
	);
};
export default CustomAutocomplete;

/*
<CustomAutocomplete
    suggestions={lavorazioniAutocomplete} --> ARRAY DI OGGETTI 
    fields={["Lavorazione"]} --> PROPRIETA' DELL'OGGETTO
    keyField="ID" --> id dell'oggetto all'interno dell'array "suggestions"
    value={lavorazioneAssociata.IDLav} --> VALORE OGGETTO SELEZIONATO
    id="Lavorazione" 
    label="Lavorazione"
    name="Lavorazione"
    onChange={(e) => {
    let lav = { ...lavorazioneAssociata };
    lav.IDLav = e;
    setLavorazioneAssociata(lav);
    }}
/>




const newFilteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(input.toLowerCase()) > -1
    );



*/
