import React, { useState } from "react";

import { FaUserCircle } from "react-icons/fa";

const Profile = () => {
	const [user, setUser] = useState({
		nome: sessionStorage.getItem("nome"),
		username: sessionStorage.getItem("username"),
		email: sessionStorage.getItem("email"),
	});
	const rank = Number(sessionStorage.getItem("rank"));

	const changeValue = (e) => {
		setUser((usr) => {
			return { ...usr, [e.target.name]: e.target.value };
		});
	};
	const handleSave = async (e) => {};

	return (
		<>
			<div className="h- flex w-full items-center justify-center">
				<div className=" w-full md:mt-0 md:max-w-lg bg-white/20 shadow-lg ring-1 ring-black/5 backdrop-blur-sm px-4 py-6 md:px-8 md:py-8 space-y-4 md:space-y-8 ">
					<div className="flex justify-center items-center rounded-full">
						<div className="bg-white rounded-full">
							<FaUserCircle className="text-2xl md:text-8xl text-dark-blue" />
						</div>
					</div>
					<div className="space-y-2 md:space-y-4" autoComplete="nope">
						<div>
							<label className="label-text">Name</label>
							<input autoComplete="off" type="text" name="nome" id="nome" value={user.nome} className="input-text" placeholder="Inserisci l'nome" required="" onChange={changeValue} />
						</div>
						<div>
							<label className="label-text">Username</label>
							<input autoComplete="off" type="text" name="username" id="username" value={user.username} className="input-text" placeholder="Inserisci l'username" required="" onChange={changeValue} />
						</div>
						<div>
							<label className="label-text">Email</label>
							<input autoComplete="off" type="email" name="email" id="email" value={user.email} className="input-text" placeholder="Inserisci l'email" required="" onChange={changeValue} />
						</div>
						{rank === 1 && (
							<button onClick={handleSave} className="button-login">
								Salva
							</button>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default Profile;
