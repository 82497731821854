import React from 'react';
import { Navigate } from 'react-router-dom';
 
function PrivateRoute({ children }) {
  const logok = useAuth();


  return logok ? children : <Navigate to="/login" />;
}
export default PrivateRoute;

const useAuth =() => {
  if (sessionStorage.getItem('logged') || localStorage.getItem('logged')  ) {
    return true;
}

return false;
}
