import React, { useState } from "react";
import logoAI from "../assets/chatgptWhite.png";
import { useNavigate } from "react-router-dom";
import { Card } from "./utility/Card";
import { FaFile, FaFolder, FaImage } from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";

const Homepage = () => {
	const navigate = useNavigate();
	const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
	const [effect, setEffect] = useState(0);
	const slides = [
		<Card
			link={() => navigate("/Jobs")}
			title={"Jobs"}
			text={
				"Scopri adesso Miller File Genius, la nostra straordinaria funzione che ti consente di caricare un documento e di effettuare richieste e analisi legate al suo contenuto."
			}
			icon={<FaMessage size={32} />}
		/>,
		<Card
			link={() => navigate("/Progetti")}
			title={"Progetti"}
			text={
				"Inizia ora a creare il tuo progetto personalizzato per soddisfare al meglio le tue esigenze. Compila il Business Profile e effettua una verifica dei tuoi concorrenti. Appena il documento sarà completo, ti verrà inviato direttamente alla tua casella di posta!"
			}
			icon={<FaFolder size={32} />}
		/>,
		<Card
			link={() => navigate("/MillerFileGenius")}
			title={"Miller File Genius"}
			text={
				"Scopri adesso Miller File Genius, la nostra straordinaria funzione che ti consente di caricare un documento e di effettuare richieste e analisi legate al suo contenuto."
			}
			icon={<FaFile size={32} />}
		/>,
		<Card
			link={() => navigate("/MillerImageGen")}
			title={"Miller Image Gen"}
			text={
				"Scopri adesso Miller Image Gen, la nostra straordinaria funzione che ti consente di generare immagini sbalorditive sfruttando le nostre API avanzate. Che tu sia un artista, un designer o semplicemente un appassionato di creazione visiva, Miller Image Gen ti offre un'esperienza intuitiva e creativa."
			}
			icon={<FaImage size={32} />}
		/>,
	];

	document.addEventListener("touchstart", handleTouchStart, false);
	document.addEventListener("touchmove", handleTouchMove, false);

	var xDown = null;
	var yDown = null;

	function getTouches(evt) {
		return (
			evt.touches || // browser API
			evt.originalEvent.touches
		); // jQuery
	}

	function handleTouchStart(evt) {
		const firstTouch = getTouches(evt)[0];
		xDown = firstTouch.clientX;
		yDown = firstTouch.clientY;
	}

	function handleTouchMove(evt) {
		if (!xDown || !yDown) {
			return;
		}

		var xUp = evt.touches[0].clientX;
		var yUp = evt.touches[0].clientY;

		var xDiff = xDown - xUp;
		var yDiff = yDown - yUp;
		//The Math.abs() static method returns the absolute value of a number
		if (Math.abs(xDiff) > Math.abs(yDiff)) {
			if (xDiff > 0) {
				handleNextSlide();
			} else {
				handlePrevSlide();
			}
		}
		/* reset values */
		xDown = null;
		yDown = null;
	}

	const handlePrevSlide = () => {
		setEffect(1);
		const isFirstSlide = currentSlideIndex === 0;
		const newIndex = isFirstSlide ? 3 : currentSlideIndex - 1;
		setCurrentSlideIndex(newIndex);
		resetEffect();
	};

	const handleNextSlide = () => {
		setEffect(2);
		const isLastSlide = currentSlideIndex === 3;
		const newIndex = isLastSlide ? 0 : currentSlideIndex + 1;
		setCurrentSlideIndex(newIndex);
		resetEffect();
	};

	const handleDotClick = (slideIndex) => {
		if (slideIndex === currentSlideIndex) {
			return;
		} else if (slideIndex > currentSlideIndex) {
			setEffect(2);
		} else {
			setEffect(1);
		}
		setCurrentSlideIndex(slideIndex);
		resetEffect();
	};

	const resetEffect = () => {
		setTimeout(() => {
			setEffect(0);
		}, 600);
	};

	return (
		<div className=" grid grid-cols-2 gap-10">
			<div className="col-span-2 bg-white/20 shadow-lg ring-1 ring-black/5 backdrop-blur-sm mt-4 p-4 sm:p-7 flex">
				<div className="relative w-full h-fit">
					<div className="absolute inset-y-0 right-0 top-0 pointer-events-none z-10 hidden xl:block ">
						<img src={logoAI} alt="" className="h-20 w-20 " />
					</div>
					<h1 className="text-xl sm:text-3xl text-white font-bold mb-1">
						Benvenuta/o, {sessionStorage.getItem("nome")}.
					</h1>
					<p className="text-white w-full lg:w-5/6 text-justify leading-tight">
						Trasforma le tue idee in documenti straordinari grazie a Miller e
						l'intelligenza artificiale di OpenAI: l'app perfetta per la
						creazione rapida e impeccabile di documenti.
					</p>
				</div>
			</div>

			{/* <div className="col-span-2 h-full flex items-center my-auto lg:hidden">
				{slides.length > 0 ? (
					<>
						<div className="w-full">
							<div className="w-full h m-auto p-0 flex justify-center items-center gap-x-5 relative group h-80 sm:h-72">
								<div
									className="w-2/3 sm:w-1/2 h-full absolute -left-[55%] sm:-left-[30%] opacity-70 image"
									effect={effect}
								>
									{slides[currentSlideIndex]}
								</div>
								<div
									className="w-2/3 sm:w-1/2 h-full flex justify-center image"
									effect={effect}
								>
									{currentSlideIndex === 3
										? slides[0]
										: slides[currentSlideIndex + 1]}
								</div>
								<div
									className="w-2/3 sm:w-1/2 h-full absolute -right-[55%] sm:-right-[30%] opacity-70 image"
									effect={effect}
								>
									{currentSlideIndex === 2
										? slides[0]
										: currentSlideIndex === 3
										? slides[1]
										: slides[currentSlideIndex + 2]}
								</div>
							</div>
							<div className="flex flex-1 justify-center items-end py-4 gap-4">
								{slides.map((slide, slideIndex) => (
									<div
										key={slideIndex}
										onClick={() => handleDotClick(slideIndex)}
										className="group text-2xl cursor-pointer duration-500 transition-all hover:scale-110 py-4"
									>
										<div
											className={`duration-700 transition-all w-16 backdrop-blur-md ${
												currentSlideIndex === slideIndex
													? " bg-white/80 h-2.5"
													: " bg-white/50 h-1.5"
											}`}
										></div>
									</div>
								))}
							</div>
						</div>
					</>
				) : (
					<></>
				)}
			</div> */}

			<div className="box-home group" onClick={() => navigate("/Progetti")}>
				{/* <div className="absolute -translate-y-[50%] top-[50%] right-8 rotate-12 z-0 text-white/30">
          <FaFolder size={180} />
        </div> */}
				<span className="z-10 relative flex h-3 w-3 items-end group-hover:scale-105 duration-300">
					<span className=" animate-bounce inline-block whitespace-nowrap rounded-[0.27rem] bg-primary-100 px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-[0.75em] font-bold leading-none text-primary-700"></span>
				</span>

				<div className="px-6 py-4 group-hover:scale-105 duration-300 z-10">
					<div className="font-bold text-xl mb-2 flex justify-between ">
						<span>Progetti</span>
						<FaFolder size={32} />
					</div>
					<p className=" text-white text-justify">
						Inizia ora a creare il tuo progetto personalizzato per soddisfare al
						meglio le tue esigenze. Compila il Business Profile e effettua una
						verifica dei tuoi concorrenti. Appena il documento sarà completo, ti
						verrà inviato direttamente alla tua casella di posta!
					</p>
					{/* <div className="flex justify-end mt-2">
            <button
              onClick={() => navigate("/Progetti")}
              className="button-prophet"
            >
              <FaFolder color="white" />
              Apri
            </button>
          </div> */}
				</div>
			</div>
			<div
				className="box-home group bbetaHidden"
				onClick={() => navigate("/MillerFileGenius")}
			>
				<span className="relative flex h-3 w-3 items-end group-hover:scale-105 duration-300">
					<span className=" animate-bounce inline-block whitespace-nowrap bg-primary-100 px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-[0.75em] font-light leading-none text-primary-700">
						Beta
					</span>
				</span>
				<div className="px-6 py-4 group-hover:scale-105 duration-300">
					<div className="font-bold text-xl mb-2 flex justify-between ">
						<span>Miller File Genius</span>
						<FaFile size={32} />
					</div>
					<p className=" text-white text-justify">
						Scopri adesso Miller File Genius, la nostra straordinaria funzione
						che ti consente di caricare un documento e di effettuare richieste e
						analisi legate al suo contenuto.
					</p>
				</div>
			</div>
			<div
				className="box-home group bbetaHidden"
				onClick={() => navigate("/MillerImageGen")}
			>
				<span className="relative flex h-3 w-3 items-end group-hover:scale-105 duration-300">
					{/* <span className=" animate-bounce inline-block whitespace-nowrap rounded-[0.27rem] bg-primary-100 px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-[0.75em] font-bold leading-none text-primary-700">
              Beta
            </span> */}
				</span>
				<div className="px-6 py-4 group-hover:scale-105 duration-300">
					<div className="font-bold text-xl mb-2 flex justify-between ">
						<span>Miller Image Gen</span>
						<FaImage size={32} />
					</div>
					<p className=" text-white text-justify">
						Scopri adesso Miller Image Gen, la nostra straordinaria funzione che
						ti consente di generare immagini sbalorditive sfruttando le nostre
						API avanzate. Che tu sia un artista, un designer o semplicemente un
						appassionato di creazione visiva, Miller Image Gen ti offre
						un'esperienza intuitiva e creativa.
					</p>
				</div>
			</div>
			<div
				className="box-home group bbetaHidden"
				onClick={() => navigate("/Jobs")}
			>
				<span className="relative flex h-3 w-3 items-end group-hover:scale-105 duration-300">
					{/* <span className=" animate-bounce inline-block whitespace-nowrap rounded-[0.27rem] bg-primary-100 px-[0.65em] pb-[0.25em] pt-[0.35em] text-center align-baseline text-[0.75em] font-bold leading-none text-primary-700">
              Beta
            </span> */}
				</span>
				<div className="px-6 py-4 group-hover:scale-105 duration-300">
					<div className="font-bold text-xl mb-2 flex justify-between ">
						<span>Job</span>
						<FaMessage size={32} />
					</div>
					<p className=" text-white text-justify">
						Scopri adesso Miller Image Gen, la nostra straordinaria funzione che
						ti consente di generare immagini sbalorditive sfruttando le nostre
						API avanzate. Che tu sia un artista, un designer o semplicemente un
						appassionato di creazione visiva, Miller Image Gen ti offre
						un'esperienza intuitiva e creativa.
					</p>
				</div>
			</div>
		</div>
	);
};

export default Homepage;
