export const Modal = ({ open, handleClose, children }) => {
  return (
    <>
      {open && (
        <div
          className="fixed inset-0 z-[120] w-full h-full bg-white/50"
          onClick={() => handleClose()}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className={`fixed left-[50%] -translate-x-[50%] top-[50%] -translate-y-[50%] bg-dark-blue p-4 w-full max-w-2xl z-50 ${
              open ? "opacity-100" : "opacity-0"
            }`}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};
