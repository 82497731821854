import React, { useState, useRef } from "react";

import api from "./api/api";
import { TextField, Autocomplete } from "@mui/material";
import socketIO from "socket.io-client";
import { FaPaperPlane } from "react-icons/fa";
//const socket = socketIO.connect('http://localhost:3000', {transports:['websocket']});
const socket = socketIO.connect("https://backend-prophetai-nwhsg4umjq-ey.a.run.app", {
  transports: ["websocket"],
});
//prova
const mansioni = [
  { label: "Advertiser" },
  { label: "Brand Strategist" },
  { label: "Content Creator" },
  { label: "Creative Director" },
  { label: "Content Marketer" },
  { label: "Creative Strategist" },
  { label: "Data Analyst" },
  { label: "Digital Strategist" },
  { label: "Email Marketing Expert" },
  { label: "Marketing Strategist" },
  { label: "PPC specialist" },
  { label: "Prompt Engineer" },
  { label: "Psicologo Comportamentale" },
  { label: "Seo Manager" },
  { label: "Social Media Manager" },
  { label: "UX Design Expert" },
];

const Collega = () => {
  const [file, setFile] = useState(null);
  const [sended, setSended] = useState(false);
  const [answer, setAnwser] = useState("");
  const chatContainer = useRef();

  const [job, setJob] = useState("");

  const [messages, setMessages] = useState([
    {
      id: 1,
      text: "Ciao, sono il tuo collega virtuale. Se non hai già scelto una mansione, fallo dal menù a tendina in alto, sarò lieto di aiutarti in qualsiasi tuo problema",
      sender: "bot",
    },
  ]);

  const [domanda, setDomanda] = useState("");

  const askToAi = (e) => {
    e.preventDefault();
    if (job === "") {
      setSended(true);
    } else {
      setSended(false);
      let obj = {};
      obj.job = job;
      obj.domanda = domanda;

      socket.emit("mex", obj);

      if (domanda.trim() !== "") {
        setDomanda("");
      }
      let m = [...messages];
      m.push({ sender: "user", text: domanda });
      setMessages(m);
    }
  };

  socket.on("response", (arg) => {
    // world

    let m = [...messages];
    m.push({ sender: "bot", text: arg.response });
    setMessages(m);
  });

  const handleInputChange = (event) => {
    setDomanda(event.target.value);
  };

  const askToFile = async () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("question", domanda);

    let res = await api.post("readDocument", formData, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    setAnwser(res.data.results);
  };

  const handleJobChange = async (selection) => {
    if (selection) {
      setJob(selection.label);
      socket.emit("setJob", selection.label);
    } else {
      setJob("");
    }
  };

  return (
    <>
      <div className="bg-white/20 shadow-lg ring-1 ring-black/5 backdrop-blur-lg mt-4 p-4 sm:p-7">
        <Autocomplete
          id="combo-box-demo"
          options={mansioni}
          onChange={(event, selection) => {
            handleJobChange(selection);
          }}
          className=" w-full md:w-72 text-white"
          renderInput={(params) => <TextField {...params} label="Mansione" sx={{color: "white"}} />}
        />
        <div className="flex flex-row gap-4 mt-4">
          <div className="flex flex-col flex-auto h-[60vh]">
            {/* <div className="flex flex-col flex-auto flex-shrink-0 rounded-md bg-transparent border-white border h-full p-4"> */}
            <div className="flex flex-col h-full overflow-x-auto mb-4">
              <div className="flex flex-col h-full">
                <div className="grid grid-cols-12 gap-y-2" ref={chatContainer}>
                  {messages.map((el) => {
                    return el.sender === "bot" ? (
                      <div className="col-start-1 col-end-12 md:col-end-8 rounded-lg">
                        <div className="flex flex-row items-end">
                          <div className="flex items-center justify-center h-10 w-10 rounded-full bg-dark-blue text-white font-semibold text-lg flex-shrink-0 ">
                            P
                          </div>
                          <div className="relative ml-3 text-base bg-transparent border-white border text-white font-light py-2 px-4 shadow rounded-xl">
                            {el.text}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className=" col-start-1 md:col-start-6 col-end-13 rounded-lg">
                        <div className="flex items-end justify-start flex-row-reverse">
                          <div className="flex items-center justify-center h-10 w-10 rounded-full bg-dark-blue text-white font-semibold text-lg flex-shrink-0 ">
                            U
                          </div>
                          <div className="relative mr-3 text-base  bg-white/10 border-white border text-white font-light py-2 px-4 shadow rounded-xl">
                            {el.text}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <form
              className="flex flex-row items-end w-full gap-4"
              onSubmit={askToAi}
            >
              <div className="flex flex-wrap w-full">
                {sended ? (
                  <div className="text-white text-xs md:text-sm font-light mb-1 ">
                    Scegli una Mansione prima di inviare
                  </div>
                ) : (
                  <></>
                )}
                <input
                  type="text"
                  className="input-text"
                  placeholder="Inserisci una domanda"
                  value={domanda}
                  onChange={handleInputChange}
                />
              </div>
              <button
                onClick={askToAi}
                type="submit"
                className="button-prophet"
              >
                <FaPaperPlane color="white"/>
                Invia
              </button>
            </form>
            {/* </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Collega;
