import { Autocomplete, Box, CircularProgress, Paper, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { FaPen, FaCheck } from "react-icons/fa";
import { IoReload } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import api from "./api/api";
import ReactMarkdown from "react-markdown";

const DettaglioProgetto = () => {
	const location = useLocation();
	const [projectDetail, setProjectDetail] = useState([]);
	const [aiData, setAiData] = useState([]);
	const tableRef = useRef(null);
	const [value, setValue] = useState(0);
	const [showApplica, setShowApplica] = useState(false);
	const [rotatedRows, setRotatedRows] = useState([]);
	const rank = Number(sessionStorage.getItem("rank"));

	const handleChangeTab = (event, newValue) => {
		setValue(newValue);
	};

	useEffect(() => {
		loadProjectDetail(location.state.ID);
		getVersions();
	}, []);

	const loadProjectDetail = async (id) => {
		let responseAiData = await api.get(`aiData/${id}`);
		setAiData(responseAiData.data.data);
		let responseProjectDetail = await api.get(`progetti/${id}`);
		setProjectDetail(responseProjectDetail.data.data);
	};

	const getVersions = async () => {
		let res = await api.get(`progetti/versions/${location.state.ID}`);
	};

	const handleSelectPrompt = async (idPrompt, row) => {
		try {
			const result = await api.get(`progetti/prompt/${row}`);
			//console.log(result);

			// Update rotation for the clicked row
			const updatedDetail = projectDetail.map((item, index) => {
				if (index === idPrompt || result.data.includes(item.IDPrompt)) {
					return { ...item, rotation: !item.rotation };
				}
				return item;
			});

			// Collect rows with rotation set to true into an array
			const rotatedRows = updatedDetail.reduce((acc, item) => {
				if (item.rotation) {
					acc.push(item);
				}
				return acc;
			}, []);

			setProjectDetail(updatedDetail);
			setRotatedRows(rotatedRows);
		} catch (error) {
			console.log(error);
		}
	};

	const handleRigenera = async () => {
		try {
			const res = await api.post("progetti/rigenera", {
				rotatedRows,
				projectDetail,
			});
		} catch (error) {
			console.log(error);
		}
	};

	//DA VEDERE SE QUESTO FUNZIONA CHAIN
	/* const handleSelectPrompt = async (idPrompt, row) => {
		let det = [...projectDetail];

		try {
			const result = await api.get(`progetti/prompt/${row}`);
			//console.log(result);
			det[idPrompt].rotation = !det[idPrompt].rotation;

			// Update rotation for elements with matching promptID
			det.forEach((item, index) => {
				//here most like need to set another disabled 1 to chain elements
				if (result.data.includes(item.IDPrompt)) {
					det[index].rotation = !det[index].rotation;
				}
			});
			setProjectDetail(det);
		} catch (error) {
			console.log(error);
		}

		setProjectDetail(det);
	};
 */
	useEffect(() => {
		// Check if there is at least one element with rotation = true
		const hasRotation = projectDetail.some((item) => item.rotation);
		setShowApplica(hasRotation);
	}, [projectDetail]);

	const handleModifica = () => {};

	return projectDetail.length > 0 ? (
		<>
			<div className="w-full pt-6 bg-white/20 shadow-lg ring-1 ring-black/5 backdrop-blur-lg mt-4">
				<div className="flex flex-row">
					<Tabs sx={{ paddingLeft: 3, paddingRight: 3, color: "white" }} value={value} onChange={handleChangeTab} textColor="secondary" indicatorColor="secondary" aria-label="secondary tabs example">
						<Tab label="Data" sx={{ color: "white" }} />
						<Tab label="Results" sx={{ color: "white" }} />
					</Tabs>

					{/* {value === 1 && (
						<div>
							<Autocomplete
								id="combo-box-demo"
								options={["Apple", "Banana", "Orange", "Grape", "Strawberry"]}
								className="w-44 text-white"
								onChange={() => {}}
								PaperComponent={({ children }) => (
									<Paper
										elevation={0}
										sx={{
											backgroundColor: "rgba(255, 255, 255, 0.2)",
											backdropFilter: "blur(2px)",
											color: "white",
										}}
									>
										{children}
									</Paper>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Versione"
										sx={{ color: "white" }}
									/>
								)}
							/>
						</div>
					)} */}
				</div>
				<TabPanel value={value} index={0}>
					<div className="grid grid-cols-2 w-full flex-wrap gap-2 items-center justify-center">
						<div className="col-span-2">
							<label for="text" className="label-text">
								Nome Progetto
							</label>
							<input
								type="text"
								className="input-text"
								placeholder="Nome Progetto"
								value={location.state.nomeProgetto}
								//onChange={(e) => setNomeProgetto(e.target.value)}
								disabled
							/>
						</div>

						{aiData.map((el, index) => (
							<div className="col-span-1 mb-2">
								<label for="text" className="label-text">
									{el.Field}
								</label>
								<input
									type="text"
									className="input-text"
									placeholder={`Inserire ${el.Field}`}
									name={el.Field}
									value={el.Value}
									disabled
									//onChange={(e) => handleChange(e, index)}
								/>
							</div>
						))}
					</div>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<TableContainer
						component={Paper}
						sx={{
							backgroundColor: "transparent",
							borderWidth: 1,
							borderColor: "whitesmoke",
							color: "white",
						}}
					>
						<Table sx={{ minWidth: 650 }} aria-label="simple table" ref={tableRef}>
							<TableHead>
								<TableRow>
									{rank >= 1 && (
										<TableCell>
											<span className="text-white text-lg font-light">Final prompt</span>
										</TableCell>
									)}
									<TableCell>
										<span className="text-white text-lg font-light">Output</span>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{projectDetail.map((row, index) => (
									<TableRow key={row.idPrompt} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
										{/* Conditional rendering based on rank */}
										{rank === 0 ? (
											// When rank is 0, only show the Output
											<TableCell style={{ whiteSpace: "pre-wrap" }}>
												<span className="text-white text-base font-light">{row.Response}</span>
											</TableCell>
										) : (
											// When rank is 1, show both Final Prompt and Output
											<>
												<TableCell style={{ whiteSpace: "pre-wrap" }} className="relative">
													<div className="flex flex-col text-white ">
														<span className="text-base font-light my-10">{row.FinalPrompt}</span>
													</div>
												</TableCell>
												<TableCell style={{ whiteSpace: "pre-wrap" }}>
													<span className="text-white text-base font-light">{row.Response}</span>
												</TableCell>
											</>
										)}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</TabPanel>
			</div>
			{value === 1 && showApplica && (
				<button className={`button-prophet-sticky sticky left-90 bottom-0 opacity-80 space-x-2 my-6`} onClick={handleRigenera}>
					<FaCheck size={24} />
					<div className="text-xl">GENERA</div>
				</button>
			)}
		</>
	) : (
		<CircularProgress />
	);
};

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

export default DettaglioProgetto;
