import React, { useEffect, useState } from "react";

import api from "./api/api";
import socketIO from "socket.io-client";
import { FaPaperPlane } from "react-icons/fa";
const socket = socketIO.connect("http://localhost:3000", {
  transports: ["websocket"],
});

const MillerFileGenius = () => {
  const [file, setFile] = useState(null);
  const [text, setText] = useState("");
  const [answer, setAnwser] = useState("");

  const [domanda, setDomanda] = useState("");

  const emitData = async () => {
    socket.emit("mex", domanda);
    /* 
 const socket = socketIO.connect('http://localhost:3000', {transports:['websocket']});
 socket.emit("mex",domanda)
 <input value={domanda}   onChange={(e) => setDomanda(e.target.value)}/>
 <button onClick={emitData}>COAP</button>*/
  };

  const generateImage = async () => {
    // setLoading(true);
    // let res = await api.post("/generateAI",{image:imageText,username:sessionStorage.getItem("username")})
    // setImageUrl(res.data.image_url)
    //setLoading(false);
    // loadProgetti();
  };

  useEffect(() => {
    loadProgetti();
  }, []);

  const loadProgetti = async () => {
    await api.get("aiImage", {
      params: { username: sessionStorage.getItem("username") },
    });
    //setImages(res.data.data);
  };

  const uploadFile = (event) => {
    let file = event.target.files[0];
    setFile(file);

    if (file) {
      let data = new FormData();
      data.append("file", file);
      // axios.post('/files', data)...
    }
  };

  const askToFile = async () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("question", text);

    let res = await api.post("readDocument", formData, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    setAnwser(res.data.results);
  };

  return (
    <>
      <div className="grid grid-cols-3 gap-4 mt-4">
        <div className="col-span-3 sm:col-span-1 bg-white/20 shadow-lg ring-1 ring-black/5 backdrop-blur-lg ">
          
            {/* <span className="absolute inset-y-0 right-0 top-0 pointer-events-none z-50 hidden xl:block h-20 w-20 light-logo"></span> */}

            <label className="w-full flex flex-col items-center px-4 py-2 sm:py-6  text-white  tracking-wide uppercase cursor-pointer">
              <svg
                className="w-8 h-8"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1zM11 11h3l-4-4-4 4h3v3h2v-3z" />
              </svg>
              <span className="mt-2 text-base leading-normal text-center">Carica file</span>
              <input type="file" className="hidden" onChange={uploadFile} />
            </label>
            <input
              className="hidden"
              value={domanda}
              onChange={(e) => setDomanda(e.target.value)}
            />
            {/* <button onClick={emitData}>COAP</button>*/}
          
        </div>
        <div className="col-span-3 sm:col-span-2 flex items-center bg-white/20 shadow-lg ring-1 ring-black/5 backdrop-blur-lg p-4 sm:p-7  ">
          <input
            placeholder="Nome File"
            type="text"
            className="input-text"
            value={file ? file.name : ""}
          />
        </div>
      
        {file ? (
          <div className="col-span-3 bg-white/20 shadow-lg ring-1 ring-black/5 backdrop-blur-lg p-4 sm:p-7  ">
            <input
              placeholder="Inserisci Testo"
              type="text"
              className="input-text"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
            <center>
              <button
                type="submit"
                onClick={askToFile}
                className="button-prophet mt-5"
              >
                <FaPaperPlane color="white"/>
                Chiedi
              </button>
            </center>
          </div>
        ) : (
          ""
          )}

        {answer ? (
          <div className="col-span-3 bg-white/20 shadow-lg ring-1 ring-black/5 backdrop-blur-lg p-4 sm:p-7  ">
            <textarea
              placeholder="Risposta"
              type="text"
              className="input-text"
              multiline
              value={answer}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default MillerFileGenius;
