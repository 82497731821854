import  React,{useEffect, useState,useRef} from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Backdrop, Button, Grid, TextField } from '@mui/material';
import api from './api/api';
import { useDownloadExcel } from 'react-export-table-to-excel';
const Automation = () => {
const [automationData,setAutomationData] = useState([]);
const [dataToEdit,setDataToEdit] = useState([]);
const [result,setResult] = useState([
  {
    Objective: 'Competitor Overview',
    idPrompt: 1,
    finalPrompt: "Voglio che tu agisca come un market researcher con molta esperienza. Scrivi una lista dei 3 principali competitor nella categoria nominata qui sotto. e inserisci l'URL del loro sito web, instagram, facebook, tiktok, pinterest, linkedin. \n" +
      'Categoria prodotto: Shampoo per capelli organico e ecosostenibile\n' +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.',
    Response: '\n' +
      '\n' +
      '1. Greenatural - Sito web: https://www.greenatural.it/ Instagram: https://www.instagram.com/greenatural_official/ Facebook: https://www.facebook.com/GreenaturalItalia/ TikTok: https://www.tiktok.com/@greenatural_official Pinterest: https://www.pinterest.it/greenatural_official/ LinkedIn: https://www.linkedin.com/company/greenatural/\n' +
      '\n' +
      '2. Bioearth - Sito web: https://www.bioearth.it/ Instagram: https://www.instagram.com/bioearth_official/ Facebook: https://www.facebook.com/BioearthItalia/ TikTok: https://www.tiktok.com/@bioearth_official Pinterest: https://www.pinterest.it/bioearth_official/ LinkedIn: https://www.linkedin.com/company/bioearth/\n' +
      '\n' +
      '3. NaturaSì - Sito web: https://www.naturasi.it/ Instagram: https://www.instagram.com/naturasi_official/ Facebook: https://www.facebook.com/NaturasiItalia/ TikTok: https://www.tiktok.com/@naturasi_official Pinterest: https://www.pinterest.it/naturasi_official/ LinkedIn: https://www.linkedin.com/company/naturasi/'
  },
  {
    Objective: 'Top benefits by Competitor',
    idPrompt: 2,
    finalPrompt: 'Ora analizza i siti web e canali social di ogni brand e definisci i 3 benefici principali del prodotto di ogni brand\n' +
      '\n' +
      '\n' +
      '1. Greenatural - Sito web: https://www.greenatural.it/ Instagram: https://www.instagram.com/greenatural_official/ Facebook: https://www.facebook.com/GreenaturalItalia/ TikTok: https://www.tiktok.com/@greenatural_official Pinterest: https://www.pinterest.it/greenatural_official/ LinkedIn: https://www.linkedin.com/company/greenatural/\n' +
      '\n' +
      '2. Bioearth - Sito web: https://www.bioearth.it/ Instagram: https://www.instagram.com/bioearth_official/ Facebook: https://www.facebook.com/BioearthItalia/ TikTok: https://www.tiktok.com/@bioearth_official Pinterest: https://www.pinterest.it/bioearth_official/ LinkedIn: https://www.linkedin.com/company/bioearth/\n' +
      '\n' +
      '3. NaturaSì - Sito web: https://www.naturasi.it/ Instagram: https://www.instagram.com/naturasi_official/ Facebook: https://www.facebook.com/NaturasiItalia/ TikTok: https://www.tiktok.com/@naturasi_official Pinterest: https://www.pinterest.it/naturasi_official/ LinkedIn: https://www.linkedin.com/company/naturasi/',
    Response: '\n' +
      '\n' +
      'Greenatural:\n' +
      '1. Prodotti naturali certificati e di alta qualità.\n' +
      "2. Una vasta gamma di prodotti per la cura della persona, la casa e l'ambiente.\n" +
      '3. Un servizio clienti attento e disponibile.\n' +
      '\n' +
      'Bioearth:\n' +
      '1. Prodotti ecologici certificati e di alta qualità.\n' +
      "2. Una vasta gamma di prodotti per la cura della persona, la casa e l'ambiente.\n" +
      '3. Un servizio clienti attento e disponibile.\n' +
      '\n' +
      'NaturaSì:\n' +
      '1. Prodotti biologici certificati e di alta qualità.\n' +
      "2. Una vasta gamma di prodotti per la cura della persona, la casa e l'ambiente.\n" +
      '3. Un servizio clienti attento e disponibile.'
  },
  {
    Objective: 'Top USPs by Competitor',
    idPrompt: 3,
    finalPrompt: 'Ora fai la tua migliore ipotesi sulle 3 principali unique selling proposition del prodotto di ogni brand. Sii quanto più dettagliato possibile.\n' +
      '\n' +
      '\n' +
      '1. Greenatural - Sito web: https://www.greenatural.it/ Instagram: https://www.instagram.com/greenatural_official/ Facebook: https://www.facebook.com/GreenaturalItalia/ TikTok: https://www.tiktok.com/@greenatural_official Pinterest: https://www.pinterest.it/greenatural_official/ LinkedIn: https://www.linkedin.com/company/greenatural/\n' +
      '\n' +
      '2. Bioearth - Sito web: https://www.bioearth.it/ Instagram: https://www.instagram.com/bioearth_official/ Facebook: https://www.facebook.com/BioearthItalia/ TikTok: https://www.tiktok.com/@bioearth_official Pinterest: https://www.pinterest.it/bioearth_official/ LinkedIn: https://www.linkedin.com/company/bioearth/\n' +
      '\n' +
      '3. NaturaSì - Sito web: https://www.naturasi.it/ Instagram: https://www.instagram.com/naturasi_official/ Facebook: https://www.facebook.com/NaturasiItalia/ TikTok: https://www.tiktok.com/@naturasi_official Pinterest: https://www.pinterest.it/naturasi_official/ LinkedIn: https://www.linkedin.com/company/naturasi/',
    Response: '\n' +
      '\n' +
      '1. Greenatural:\n' +
      '- Prodotti naturali certificati e di alta qualità, realizzati con ingredienti biologici e sostenibili;\n' +
      '- Una vasta gamma di prodotti per la cura della pelle, dei capelli, del corpo e della casa;\n' +
      '- Un servizio clienti eccellente con una garanzia di soddisfazione al 100%.\n' +
      '\n' +
      '2. Bioearth:\n' +
      '- Prodotti certificati biologici e naturali, realizzati con ingredienti di origine vegetale;\n' +
      '- Una vasta gamma di prodotti per la cura della pelle, dei capelli, del corpo e della casa;\n' +
      '- Un servizio clienti eccellente con una garanzia di soddisfazione al 100%.\n' +
      '\n' +
      '3. NaturaSì:\n' +
      '- Prodotti certificati biologici e naturali, realizzati con ingredienti di origine vegetale;\n' +
      '- Una vasta gamma di prodotti per la cura della pelle, dei capelli, del corpo e della casa;\n' +
      '- Un servizio clienti eccellente con una garanzia di soddisfazione al 100%.'
  },
  {
    Objective: 'Pain Points',
    idPrompt: 4,
    finalPrompt: 'Ora definisci i 5 principali pain point, problemi o frustrazioni principali dei consumatori del prodotto qui sotto.\n' +
      'Categoria prodotto: Shampoo per capelli organico e ecosostenibile\n' +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.',
    Response: '\n' +
      '\n' +
      '1. Costo elevato: i prodotti organici e ecosostenibili tendono ad essere più costosi rispetto ai prodotti convenzionali.\n' +
      '2. Difficoltà di reperibilità: i prodotti organici e ecosostenibili sono spesso difficili da trovare nei negozi di alimentari e nei supermercati.\n' +
      "3. Difficoltà nell'identificare i prodotti più adatti: i consumatori possono avere difficoltà a identificare quali prodotti siano più adatti alle loro esigenze.\n" +
      "4. Difficoltà nell'identificare i prodotti più sicuri: i consumatori possono avere difficoltà a identificare quali prodotti siano più sicuri per i loro capelli.\n" +
      "5. Difficoltà nell'identificare i prodotti più efficaci: i consumatori possono avere difficoltà a identificare quali prodotti siano più efficaci per ottenere capelli luminosi e sani."
  },
  {
    Objective: 'Goals & Desires',
    idPrompt: 5,
    finalPrompt: 'Ora definisci dal punto di vista emozionale quali sono i 3-5 principali desideri e ambizioni dei consumatori di prodotti indicati qui sotto.\n' +
      'Categoria prodotto: Shampoo per capelli organico e ecosostenibile\n' +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.',
    Response: '\n' +
      '\n' +
      '1. Sentirsi sicure che i loro capelli siano trattati con prodotti naturali e sostenibili.\n' +
      '2. Avere capelli sani, luminosi e ben curati.\n' +
      '3. Sentirsi più belle e a proprio agio con i propri capelli.\n' +
      '4. Sentirsi parte di una comunità di persone che condividono gli stessi valori di sostenibilità.\n' +
      '5. Avere un prodotto che sia efficace e di qualità.'
  },
  {
    Objective: 'Benefits',
    idPrompt: 6,
    finalPrompt: 'Ora definisci dal punto di vista funzionale quali sono i 3-5 principali benefici del prodotto indicato qui sotto:\n' +
      'Categoria prodotto: Shampoo per capelli organico e ecosostenibile\n' +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.',
    Response: '\n' +
      '\n' +
      "1. Prodotto con ingredienti naturali e organici, che non danneggiano i capelli e rispettano l'ambiente.\n" +
      '2. Formula leggera che deterge i capelli in profondità, senza appesantirli.\n' +
      '3. Nutrimento e idratazione profonda per capelli luminosi e sani.\n' +
      "4. Protezione dai danni causati dai raggi UV, dall'inquinamento e dai prodotti chimici.\n" +
      '5. Facile da usare e da risciacquare, con un profumo gradevole.'
  },
  {
    Objective: 'FAQ',
    idPrompt: 7,
    finalPrompt: 'Ora definisci le 5 principali FAQ (domande più frequenti) effettuate dagli utenti online sulla categoria di prodotto qui sotto.\n' +
      'Categoria prodotto: Shampoo per capelli organico e ecosostenibile\n' +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.',
    Response: '\n' +
      '\n' +
      '1. Quali sono gli ingredienti dello shampoo?\n' +
      "2. Quali sono i benefici dell'utilizzo di questo shampoo?\n" +
      '3. Qual è la durata dello shampoo?\n' +
      '4. Come posso sapere se questo shampoo è adatto ai miei capelli?\n' +
      '5. Quali sono le modalità di spedizione disponibili?'
  },
  {
    Objective: 'Seed keywords',
    idPrompt: 8,
    finalPrompt: 'Ora fornisci 50 search keywords ad alto intento legate alla categoria di prodotto qui sotto.\n' +
      'Categoria prodotto: Shampoo per capelli organico e ecosostenibile',
    Response: '\n' +
      '\n' +
      '1. Shampoo organico\n' +
      '2. Shampoo naturale\n' +
      '3. Shampoo biologico\n' +
      '4. Shampoo ecologico\n' +
      '5. Shampoo ecosostenibile\n' +
      '6. Shampoo a base di erbe\n' +
      '7. Shampoo senza solfati\n' +
      '8. Shampoo senza parabeni\n' +
      '9. Shampoo senza siliconi\n' +
      '10. Shampoo senza fragranze artificiali\n' +
      '11. Shampoo privo di sostanze chimiche\n' +
      '12. Shampoo a base di ingredienti naturali\n' +
      '13. Shampoo a base di oli essenziali\n' +
      '14. Shampoo a base di erbe medicinali\n' +
      '15. Shampoo a base di erbe per capelli\n' +
      '16. Shampoo a base di erbe per capelli secchi\n' +
      '17. Shampoo a base di erbe per capelli grassi\n' +
      '18. Shampoo a base di erbe per capelli danneggiati\n' +
      '19. Shampoo a base di erbe per capelli colorati\n' +
      '20. Shampoo a base di erbe per capelli fragili\n' +
      '21. Shampoo a base di erbe per capelli sottili\n' +
      '22. Shampoo a base di erbe per capelli ricci\n' +
      '23. Shampoo a base di erbe per capelli lisci\n' +
      '24. Shampoo a base di erbe per capelli lunghi\n' +
      '25. Shampoo a base di erbe per capelli corti\n' +
      '26. Shampoo a base di erbe per capelli fini\n' +
      '27. Shampoo a base di erbe per capelli spessi\n' +
      '28. Shampoo a base di erbe per capelli secchi\n' +
      '29. Shampoo a base di erbe per capelli grassi\n' +
      '30. Shampoo a base di erbe per capelli fragili\n' +
      '31. Shampoo a base di erbe per capelli sfibrati\n' +
      '32. Shampoo a base di erbe per capelli spenti\n' +
      '33. Shampoo a base di erbe per capelli sfibrati\n' +
      '34. Shampoo a base di erbe per capelli spenti\n' +
      '35. Shampoo a base di erbe per capelli stressati\n' +
      '36. Shampoo a base di erbe per capelli colorati\n' +
      '37. Shampoo a base di erbe per capelli trattati\n' +
      '38. Shampoo a base di erbe per capelli secchi e danneggiati\n' +
      '39. Shampoo a base di erbe per capelli sottili e fragili\n' +
      '40. Shampoo a base di erbe per capelli ricci e grassi\n' +
      '41. Shampoo a base di erbe per capelli lisci e lunghi\n' +
      '42. Shampoo a base di erbe per capelli fini e corti\n' +
      '43. Shampoo a base di erbe per capelli spessi e sfibrati\n' +
      '44. Shampoo a base di erbe per capelli spenti e stressati\n' +
      '45. Shampoo a base di erbe per capelli trattati e colorati\n' +
      '46. Shampoo organico per capelli\n' +
      '47. Shampoo naturale per capelli\n' +
      '48. Shampoo biologico per capelli\n' +
      '49. Shampoo ecologico per capelli\n' +
      '50. Shampoo ecosostenibile per capelli'
  },
  {
    Objective: 'Offers, Incentives & Promotional Plan',
    idPrompt: 9,
    finalPrompt: 'Ora agisci come un marketing strategist. Sulla base delle informazioni qui sotto, fornisci:\r\n' +
      '\r\n' +
      '- 10 offerte da utilizzare per il nostro prodotto\r\n' +
      "- 10 attività promozionali da utilizzare all'interno della strategia di marketing\r\n" +
      '- 10 incentivi non monetari\n' +
      'Categoria prodotto: Shampoo per capelli organico e ecosostenibile\n' +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.',
    Response: '\n' +
      '\n' +
      'Offerte:\n' +
      '1. Offerta di lancio: acquista uno shampoo e ricevi uno sconto del 20% su un balsamo abbinato.\n' +
      '2. Offerta di prova: acquista una confezione di shampoo e ricevi una confezione di balsamo gratuita.\n' +
      '3. Offerta di abbonamento: acquista un abbonamento mensile e ricevi uno sconto del 10% su tutti i prodotti.\n' +
      '4. Offerta di gruppo: acquista 3 confezioni di shampoo e ricevi una confezione di balsamo gratuita.\n' +
      '5. Offerta di ricompensa: acquista una confezione di shampoo e ricevi un buono sconto del 10% su una confezione di balsamo.\n' +
      '6. Offerta di scambio: acquista una confezione di shampoo e ricevi una confezione di balsamo a metà prezzo.\n' +
      '7. Offerta di acquisto multiplo: acquista 3 confezioni di shampoo e ricevi uno sconto del 15% su una confezione di balsamo.\n' +
      '8. Offerta di soddisfazione garantita: acquista una confezione di shampoo e se non sei soddisfatto, ricevi un rimborso completo.\n' +
      '9. Offerta di prova gratuita: ricevi una confezione di shampoo gratuita per testare il prodotto.\n' +
      '10. Offerta di rinnovo: acquista una confezione di shampoo e ricevi uno sconto del 10% su una confezione di balsamo.\n' +
      '\n' +
      'Attività promozionali:\n' +
      '1. Campagne di email marketing per promuovere le offerte.\n' +
      '2. Campagne di social media per promuovere le offerte.\n' +
      '3. Pubblicità sui siti web e sui blog.\n' +
      '4. Pubblicità sui giornali e sui magazine.\n' +
      '5. Eventi di promozione in negozi di bellezza.\n' +
      '6. Campagne di pubblicità sui mezzi di trasporto pubblico.\n' +
      '7. Campagne di pubblicità sui social media influencer.\n' +
      '8. Campagne di pubblicità sui canali televisivi.\n' +
      '9. Campagne di pubblicità sui siti web di e-commerce.\n' +
      '10. Campagne di pubblicità sui motori di ricerca.\n' +
      '\n' +
      'Incentivi non monetari:\n' +
      '1. Buoni sconto da utilizzare sui prodotti.\n' +
      '2. Regali di benvenuto per i nuovi clienti.\n' +
      '3. Campioni gratuiti dei prodotti.\n' +
      '4. Inviti a eventi esclusivi.\n' +
      '5. Accesso anticipato a nuovi prodotti.\n' +
      '6. Buoni regalo da utilizzare presso negozi di bellezza.\n' +
      '7. Inviti a eventi di formazione sui prodotti.\n' +
      '8. Gift card da utilizzare sui prodotti.\n' +
      '9. Accesso a contenuti esclusivi.\n' +
      '10. Partecipazione a concorsi a premi.'
  },
  {
    Objective: 'Objections',
    idPrompt: 10,
    finalPrompt: 'Adesso fai una lista delle principali 3 obiezioni per cui il cliente target potrebbe decidere di non comprare sulla base del target market e delle caratteristiche del prodotto. \n' +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.\n' +
      "Descrizione prodotto: Omorfie è un brand di cura dei capelli sostenibile a base vegetale. Produce shampoo e balsami che utilizzano ingredienti organci di alta qualità e piante vegetali, senza plastiche né sostanze nocive. I prodotti non sono solo delicati sui tuoi capelli, ma anche sull'ambiente. Aiuta a risparmiare tempo e semplica la cura dei tuoi capelli.",
    Response: '\n' +
      '\n' +
      '1. Il prezzo potrebbe essere troppo alto per il budget del cliente target.\n' +
      '2. Il cliente target potrebbe non essere interessato a prodotti a base vegetale o organici.\n' +
      '3. Il cliente target potrebbe non essere interessato a prodotti che non contengono plastiche.'
  },
  {
    Objective: 'Response to objections',
    idPrompt: 11,
    finalPrompt: 'Ora fai la tua migliore ipotesi su come rispondere a queste obiezioni in modo da convincere il consumatore che il nostro prodotto è la scelta migliore sul mercato.\n' +
      '\n' +
      '\n' +
      '1. Il prezzo potrebbe essere troppo alto per il budget del cliente target.\n' +
      '2. Il cliente target potrebbe non essere interessato a prodotti a base vegetale o organici.\n' +
      '3. Il cliente target potrebbe non essere interessato a prodotti che non contengono plastiche.\n' +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.',
    Response: '\n' +
      '\n' +
      "1. Rispondiamo all'obiezione del prezzo offrendo una promozione speciale o uno sconto per i nostri clienti target. Possiamo anche offrire una garanzia di soddisfazione al 100% in modo che i nostri clienti possano essere certi di ottenere un prodotto di qualità superiore ad un prezzo accessibile.\n" +
      '\n' +
      "2. Possiamo spiegare come i nostri ingredienti naturali e organici siano stati selezionati per fornire una cura dei capelli più sana e sicura, senza compromettere l'efficacia del prodotto. Possiamo anche offrire campioni gratuiti in modo che i clienti possano testare il prodotto prima di acquistarlo.\n" +
      '\n' +
      "3. Possiamo spiegare come abbiamo scelto di non utilizzare plastiche nella produzione del nostro shampoo per ridurre l'impatto ambientale. Possiamo anche offrire una garanzia di soddisfazione al 100% in modo che i nostri clienti possano essere certi di ottenere un prodotto di qualità superiore senza plastiche."
  },
  {
    Objective: 'Behavioural Marketing Principles',
    idPrompt: 12,
    finalPrompt: "Ora agisci come uno psicologo comportamentale. Sulla base delle informazioni qui sotto, fai una lista dei principi comportamentali più rilevanti perché la target audience consideri l'acquisto del prodotto. \r\n" +
      '\r\n' +
      'Organizzali per fase del funnel (awareness, consideration, conversion)\n' +
      'Categoria prodotto: Shampoo per capelli organico e ecosostenibile\n' +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.',
    Response: '\n' +
      '\n' +
      'Awareness: \n' +
      '\n' +
      "-Fornire informazioni sui benefici dell'utilizzo di uno shampoo organico e ecosostenibile. \n" +
      "-Evidenziare l'importanza di scegliere prodotti con ingredienti organici e senza plastiche. \n" +
      "-Fornire informazioni sui danni che possono essere causati dall'utilizzo di prodotti non organici. \n" +
      '\n' +
      'Consideration: \n' +
      '\n' +
      '-Fornire informazioni su come il prodotto può aiutare a ottenere capelli luminosi e sani. \n' +
      '-Evidenziare come il prodotto sia adatto a tutti i tipi di capelli. \n' +
      '-Fornire informazioni sugli ingredienti naturali contenuti nel prodotto. \n' +
      '-Evidenziare come il prodotto sia efficace e non danneggi i capelli. \n' +
      '\n' +
      'Conversion: \n' +
      '\n' +
      '-Fornire informazioni su come il prodotto sia facile da usare. \n' +
      '-Evidenziare come il prodotto sia economico rispetto ad altri prodotti simili. \n' +
      '-Fornire informazioni su come il prodotto sia disponibile in diversi formati. \n' +
      '-Fornire informazioni su come il prodotto sia stato testato e approvato da esperti.'
  },
  {
    Objective: 'Trigger Events',
    idPrompt: 13,
    finalPrompt: `Ora fai una lista dei principali 3 "trigger event" che portano la target audience a considerare l'acquisto di un prodotto appartenente alla categoria qui sotto\n` + 
      'Categoria prodotto: Shampoo per capelli organico e ecosostenibile\n' +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.',
    Response: '\n' +
      '\n' +
      "1. Partecipazione a un evento di bellezza incentrato sui prodotti organici e sostenibili: partecipando a un evento di bellezza incentrato sui prodotti organici e sostenibili, le donne tra i 25 e i 45 anni possono scoprire e apprendere di più sui benefici dell'utilizzo di prodotti organici e sostenibili per la cura dei capelli.\n" +
      '\n' +
      "2. Sponsorizzazione di un influencer: sponsorizzando un influencer che promuove i prodotti organici e sostenibili, le donne tra i 25 e i 45 anni possono scoprire e apprendere di più sui benefici dell'utilizzo di prodotti organici e sostenibili per la cura dei capelli.\n" +
      '\n' +
      "3. Promozione di una campagna di sensibilizzazione: promuovendo una campagna di sensibilizzazione sui benefici dell'utilizzo di prodotti organici e sostenibili per la cura dei capelli, le donne tra i 25 e i 45 anni possono scoprire e apprendere di più sui benefici dell'utilizzo di prodotti organici e sostenibili per la cura dei capelli."
  },
  {
    Objective: 'Meta Ads - Problem Based Angles | PAS',
    idPrompt: 14,
    finalPrompt: 'Voglio che tu agisca come un advertiser. Scrivi 3 versioni di Meta Ads, usando angoli differenti, basati su pain points indicati qui sotto. Per ogni ads, scrivi una headline di 30-60 caratteri e un body copy di 100-300 caratteri. Lascia spazio tra i paragrafi. Utilizza il copy framework PAS (problem, agitation, solution).\n' +
      `"Costo elevato rispetto ai brand non ecosostenibili Difficoltà nel trovare il prodotto in negozi fisici Incertezza sull'efficacia del prodotto rispetto ai brand tradizionali Dubbi sulla sostenibilità effettiva del prodotto e dell'azienda Limitazione nella gamma di prodotti disponibili rispetto ai brand tradizionali."\n` +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.',
    Response: '\n' +
      '\n' +
      'Ad 1:\n' +
      'Headline: Prodotti Ecosostenibili a Prezzi Accessibili\n' +
      'Body Copy: Sei alla ricerca di prodotti ecosostenibili che siano anche accessibili? Abbiamo la soluzione per te! Abbiamo una gamma di prodotti che non solo sono sostenibili, ma anche efficaci e a prezzi accessibili. Non dovrai più preoccuparti dei costi elevati e della limitazione nella gamma di prodotti. Scegli i nostri prodotti e ottieni capelli luminosi e sani senza compromettere la sostenibilità.\n' +
      '\n' +
      'Ad 2:\n' +
      'Headline: Prodotti Ecosostenibili Senza Compromessi\n' +
      "Body Copy: Stai cercando prodotti ecosostenibili che siano anche efficaci? Abbiamo la soluzione per te! Abbiamo una gamma di prodotti che non solo sono sostenibili, ma anche efficaci. Non dovrai più preoccuparti di dubbi sull'efficacia del prodotto rispetto ai brand tradizionali. Scegli i nostri prodotti e ottieni capelli luminosi e sani senza compromettere la sostenibilità.\n" +
      '\n' +
      'Ad 3:\n' +
      'Headline: Prodotti Ecosostenibili Facili da Trovare\n' +
      'Body Copy: Stai cercando prodotti ecosostenibili che siano facili da trovare? Abbiamo la soluzione per te! Abbiamo una gamma di prodotti che non solo sono sostenibili, ma anche facili da trovare. Non dovrai più preoccuparti della difficoltà nel trovare il prodotto in negozi fisici. Scegli i nostri prodotti e ottieni capelli luminosi e sani senza compromettere la sostenibilità.'
  },
  {
    Objective: 'Meta Ads - Trigger Event Based Angle | AIDA',
    idPrompt: 15,
    finalPrompt: 'Voglio che tu agisca come un advertiser. Scrivi 3 versioni di Meta Ads, usando angoli differenti, basati su pain points indicati qui sotto. Per ogni ads, scrivi una headline di 30-60 caratteri e un body copy di 100-300 caratteri. Lascia spazio tra i paragrafi. Utilizza il copy framework AIDA (Attention, Interest, Desire, Action).\n' +
      'Cura dei capelli professionale\n' +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.',
    Response: '\n' +
      '\n' +
      'Headline 1: "Capelli sani e luminosi con ingredienti naturali"\n' +
      'Body Copy: Attenzione! Sei stanca dei tuoi capelli secchi e danneggiati? Abbiamo la soluzione perfetta per te! La nostra linea di shampoo con ingredienti naturali e senza plastiche è esattamente ciò di cui hai bisogno per ottenere capelli sani e luminosi. I nostri prodotti sono stati testati da esperti di bellezza per garantire che i tuoi capelli siano nutriti e protetti. Inizia a prenderti cura dei tuoi capelli con i nostri prodotti di qualità. Sperimenta la differenza e goditi i capelli sani e luminosi che desideri!\n' +
      '\n' +
      'Headline 2: "Capelli sani e nutriti con ingredienti organici"\n' +
      'Body Copy: Hai bisogno di prenderti cura dei tuoi capelli? Abbiamo la soluzione perfetta per te! La nostra linea di prodotti con ingredienti organici è esattamente ciò di cui hai bisogno per ottenere capelli sani e nutriti. I nostri prodotti sono stati testati da esperti di bellezza per garantire che i tuoi capelli siano nutriti e protetti. Inizia a prenderti cura dei tuoi capelli con i nostri prodotti di qualità. Sperimenta la differenza e goditi i capelli sani e nutriti che desideri!\n' +
      '\n' +
      'Headline 3: "Capelli sani e forti senza plastiche"\n' +
      'Body Copy: Stai cercando un modo per prenderti cura dei tuoi capelli? Abbiamo la soluzione perfetta per te! La nostra linea di prodotti senza plastiche è esattamente ciò di cui hai bisogno per ottenere capelli sani e forti. I nostri prodotti sono stati testati da esperti di bellezza per garantire che i tuoi capelli siano nutriti e protetti. Inizia a prenderti cura dei tuoi capelli con i nostri prodotti di qualità. Sperimenta la differenza e goditi i capelli sani e forti che desideri!'
  },
  {
    Objective: 'Meta Ads - Behavioral Marketing Based Angles ',
    idPrompt: 16,
    finalPrompt: 'Voglio che tu agisca come un advertiser. Scrivi 3 versioni di Meta Ads per ogni fase del funnel (awareness, consideration, conversion), utilizzando i principi comportamentali indicati qui sotto. Per ogni ads, scrivi una headline di 30-60 caratteri e un body copy di 100-300 caratteri. Lascia spazio tra i paragrafi.  \n' +
      '\n' +
      '\n' +
      'Awareness: \n' +
      '\n' +
      "-Fornire informazioni sui benefici dell'utilizzo di uno shampoo organico e ecosostenibile. \n" +
      "-Evidenziare l'importanza di scegliere prodotti con ingredienti organici e senza plastiche. \n" +
      "-Fornire informazioni sui danni che possono essere causati dall'utilizzo di prodotti non organici. \n" +
      '\n' +
      'Consideration: \n' +
      '\n' +
      '-Fornire informazioni su come il prodotto può aiutare a ottenere capelli luminosi e sani. \n' +
      '-Evidenziare come il prodotto sia adatto a tutti i tipi di capelli. \n' +
      '-Fornire informazioni sugli ingredienti naturali contenuti nel prodotto. \n' +
      '-Evidenziare come il prodotto sia efficace e non danneggi i capelli. \n' +
      '\n' +
      'Conversion: \n' +
      '\n' +
      '-Fornire informazioni su come il prodotto sia facile da usare. \n' +
      '-Evidenziare come il prodotto sia economico rispetto ad altri prodotti simili. \n' +
      '-Fornire informazioni su come il prodotto sia disponibile in diversi formati. \n' +
      '-Fornire informazioni su come il prodotto sia stato testato e approvato da esperti.\n' +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.',
    Response: ' \n' +
      '\n' +
      'Awareness: \n' +
      'Headline: Scopri lo shampoo organico e ecosostenibile. \n' +
      'Body Copy: Stai cercando uno shampoo che sia efficace, che non danneggi i capelli e che sia al contempo organico e ecosostenibile? Scopri come i nostri prodotti possono aiutarti a ottenere capelli luminosi e sani. Scegli prodotti con ingredienti organici e senza plastiche, per prendersi cura dei tuoi capelli in modo naturale. \n' +
      '\n' +
      'Consideration: \n' +
      'Headline: Uno shampoo adatto a tutti i tipi di capelli. \n' +
      'Body Copy: Sei alla ricerca di uno shampoo che sia adatto a tutti i tipi di capelli? Il nostro shampoo è stato appositamente formulato per soddisfare le esigenze di ogni tipo di capello. Contiene ingredienti naturali che aiutano a ottenere capelli luminosi e sani, senza danneggiarli. Scopri come il nostro prodotto può aiutarti a prenderti cura dei tuoi capelli in modo naturale. \n' +
      '\n' +
      'Conversion: \n' +
      'Headline: Uno shampoo facile da usare. \n' +
      'Body Copy: Sei alla ricerca di uno shampoo che sia facile da usare? Il nostro shampoo è stato testato e approvato da esperti per garantire risultati ottimali. È disponibile in diversi formati e a un prezzo accessibile. Scopri come il nostro prodotto può aiutarti a prenderti cura dei tuoi capelli in modo naturale, senza danneggiarli.'
  },
  {
    Objective: 'Meta Ads - Remarketing Ads',
    idPrompt: 17,
    finalPrompt: 'Voglio che tu agisca come un advertiser. Scrivi 3 versioni di Meta Ads, usando angoli differenti, basati sui seguenti principi comportamentali: social proof, scarcity, urgency, loss aversion. Per ogni ads, scrivi una headline di 30-60 caratteri e un body copy di 100-300 caratteri. Lascia spazio tra i paragrafi. Considera le seguenti informazioni come contesto:\n' +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.\n' +
      "Descrizione prodotto: Omorfie è un brand di cura dei capelli sostenibile a base vegetale. Produce shampoo e balsami che utilizzano ingredienti organci di alta qualità e piante vegetali, senza plastiche né sostanze nocive. I prodotti non sono solo delicati sui tuoi capelli, ma anche sull'ambiente. Aiuta a risparmiare tempo e semplica la cura dei tuoi capelli.",
    Response: '\n' +
      '\n' +
      '1. Meta Ads - Social Proof:\n' +
      'Headline: "Capelli sani e luminosi grazie a Omorfie!"\n' +
      'Body Copy: Sei stanca di shampoo che danneggiano i tuoi capelli? Omorfie è la soluzione! I prodotti di Omorfie sono realizzati con ingredienti organici di alta qualità e piante vegetali, senza plastiche né sostanze nocive. Grazie ai prodotti di Omorfie, milioni di donne in Italia hanno ottenuto capelli sani e luminosi. Unisciti a loro e scopri la differenza che Omorfie può fare per te!\n' +
      '\n' +
      '2. Meta Ads - Scarcity:\n' +
      'Headline: "Omorfie: prodotti per capelli limitati!"\n' +
      "Body Copy: Sei alla ricerca di uno shampoo efficace che non danneggia i tuoi capelli? Omorfie è la soluzione! I prodotti di Omorfie sono realizzati con ingredienti organici di alta qualità e piante vegetali, senza plastiche né sostanze nocive. Ma affrettati: i prodotti di Omorfie sono limitati! Non perdere l'occasione di avere capelli sani e luminosi con Omorfie!\n" +
      '\n' +
      '3. Meta Ads - Urgency:\n' +
      'Headline: "Omorfie: capelli sani e luminosi, subito!"\n' +
      "Body Copy: Sei alla ricerca di uno shampoo efficace che non danneggia i tuoi capelli? Omorfie è la soluzione! I prodotti di Omorfie sono realizzati con ingredienti organici di alta qualità e piante vegetali, senza plastiche né sostanze nocive. Non aspettare: i prodotti di Omorfie sono l'unico modo per ottenere capelli sani e luminosi in tempi brevi. Scopri la differenza che Omorfie può fare per te!"
  },
  {
    Objective: 'Google Ads - keywords',
    idPrompt: 18,
    finalPrompt: "Voglio che tu agisca come un advertiser. Fai una lista delle 50 top keywords ad alto intento per il prodotto indicato qui sotto. Raggruppale in adgroup in base al loro tema. Ogni adgroup deve fare riferimento ad un gruppo di keywords simili tra loro. L'output deve essere in questo formato:\r\n" +
      '\r\n' +
      'Campaign\r\n' +
      'Adgroup \r\n' +
      'Keywords\n' +
      'Categoria prodotto: Shampoo per capelli organico e ecosostenibile\n' +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.',
    Response: '\n' +
      '\n' +
      'Campaign: Shampoo Organico Ecosostenibile\n' +
      '\n' +
      'Adgroup 1: Shampoo Organico\n' +
      'Keywords: shampoo organico, shampoo naturale, shampoo biologico, shampoo a base di erbe, shampoo a base di oli essenziali, shampoo a base di frutta, shampoo a base di erbe, shampoo a base di aloe vera, shampoo a base di olio di cocco, shampoo a base di olio di jojoba, shampoo a base di olio di ricino, shampoo a base di olio di argan, shampoo a base di olio di mandorle, shampoo a base di olio di avocado, shampoo a base di olio di oliva\n' +
      '\n' +
      'Adgroup 2: Ecosostenibile\n' +
      'Keywords: shampoo ecosostenibile, shampoo senza plastica, shampoo senza imballaggi, shampoo senza parabeni, shampoo senza siliconi, shampoo senza SLS, shampoo senza petrolati, shampoo senza ftalati, shampoo senza coloranti, shampoo senza conservanti, shampoo senza profumi sintetici, shampoo senza ingredienti di origine animale, shampoo senza alcool, shampoo senza detergenti aggressivi\n' +
      '\n' +
      'Adgroup 3: Capelli Luminosi\n' +
      'Keywords: shampoo per capelli luminosi, shampoo per capelli sani, shampoo per capelli forti, shampoo per capelli lucenti, shampoo per capelli idratati, shampoo per capelli morbidi, shampoo per capelli setosi, shampoo per capelli brillanti, shampoo per capelli nutriti, shampoo per capelli voluminosi, shampoo per capelli elastici, shampoo per capelli ricci, shampoo per capelli ricci definiti, shampoo per capelli lisci\n' +
      '\n' +
      'Adgroup 4: Donne\n' +
      'Keywords: shampoo per donne, shampoo per donne over 25, shampoo per donne over 35, shampoo per donne over 45, shampoo per donne italiane, shampoo per donne con capelli secchi, shampoo per donne con capelli grassi, shampoo per donne con capelli colorati, shampoo per donne con capelli sfibrati, shampoo per donne con capelli danneggiati, shampoo per donne con capelli fragili, shampoo per donne con capelli fini, shampoo per donne con capelli ricci, shampoo per donne con capelli lisci'
  },
  {
    Objective: 'Google Ads - Responsive Search Ad',
    idPrompt: 19,
    finalPrompt: 'Voglio che tu agisca come un advertiser. Crea il testo di: \r\n' +
      '\r\n' +
      '- 15 Google Ads search headlines di minimo 20 e massimo 30 caratteri per il prodotto qui sotto.\r\n' +
      '- 4 Google Ads search descriptions di 90 caratteri \r\n' +
      '\r\n' +
      'Dividi il testo in:\r\n' +
      '\r\n' +
      '- Headlines\r\n' +
      '- Descriptions\r\n' +
      '\r\n' +
      '\r\n' +
      'Ogni descrizione deve mettere in risalto un beneficio differente.\r\n' +
      '\r\n' +
      'In almeno 3 headlines, includi la seed keyword. Considera le seguenti informazioni \r\n' +
      '\r\n' +
      'Seed keyword: shampoo organico\n' +
      'Categoria prodotto: Shampoo per capelli organico e ecosostenibile\n' +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.',
    Response: '\n' +
      '\n' +
      'Headlines: \n' +
      '1. Shampoo Organico per Capelli Sani\n' +
      '2. Prova lo Shampoo Organico\n' +
      '3. Capelli Luminosi con Shampoo Organico\n' +
      '4. Shampoo Organico Ecosostenibile\n' +
      '5. Shampoo Organico per Capelli Forti\n' +
      '6. Shampoo Organico per Capelli Belli\n' +
      '7. Usa lo Shampoo Organico\n' +
      '8. Shampoo Organico per Capelli Morbidi\n' +
      '9. Shampoo Organico per Capelli Sani\n' +
      '10. Shampoo Organico per Capelli Forti\n' +
      '11. Shampoo Organico per Capelli Belli\n' +
      '12. Shampoo Organico per Capelli Sani\n' +
      '13. Shampoo Organico per Capelli Luminosi\n' +
      '14. Shampoo Organico per Capelli Forti\n' +
      '15. Shampoo Organico per Capelli Sani e Luminosi\n' +
      '\n' +
      'Descriptions:\n' +
      '1. Prova lo shampoo organico per capelli sani e luminosi. Un prodotto ecologico e sostenibile che non danneggia i capelli.\n' +
      '2. Usa lo shampoo organico per avere capelli morbidi e sani. Un prodotto ecologico e sostenibile che non danneggia i capelli.\n' +
      '3. Shampoo organico per capelli forti e sani. Un prodotto ecologico e sostenibile che non danneggia i capelli.\n' +
      '4. Prova lo shampoo organico per capelli belli e sani. Un prodotto ecologico e sostenibile che non danneggia i capelli.'
  },
  {
    Objective: 'Google Ads - Sitelink Extensions',
    idPrompt: 20,
    finalPrompt: 'Voglio che tu agisca come un PPC specialist. Crea il seguente copy per il prodotto qui sotto\r\n' +
      '\r\n' +
      '- 4 Sitelink extensions differenti con suggerimento di URL\r\n' +
      '- Ogni sitelink non deve avere più di 30 caratteri\r\n' +
      '- Ogni sitelink deve avere 2 descrizioni di massimo 25 caratteri ognuna\n' +
      'Categoria prodotto: Shampoo per capelli organico e ecosostenibile\n' +
      "Descrizione prodotto: Omorfie è un brand di cura dei capelli sostenibile a base vegetale. Produce shampoo e balsami che utilizzano ingredienti organci di alta qualità e piante vegetali, senza plastiche né sostanze nocive. I prodotti non sono solo delicati sui tuoi capelli, ma anche sull'ambiente. Aiuta a risparmiare tempo e semplica la cura dei tuoi capelli.",
    Response: '\n' +
      '\n' +
      'Sitelink 1:\n' +
      'URL: Omorfie.com/shampoo\n' +
      'Descrizione 1: Shampoo Organico\n' +
      'Descrizione 2: Prodotti Ecosostenibili\n' +
      '\n' +
      'Sitelink 2:\n' +
      'URL: Omorfie.com/balsami\n' +
      'Descrizione 1: Balsami Naturali\n' +
      'Descrizione 2: Protezione dei Capelli\n' +
      '\n' +
      'Sitelink 3:\n' +
      'URL: Omorfie.com/ingredienti\n' +
      'Descrizione 1: Ingredienti Organici\n' +
      'Descrizione 2: Senza Plastica\n' +
      '\n' +
      'Sitelink 4:\n' +
      'URL: Omorfie.com/risparmio\n' +
      'Descrizione 1: Risparmia Tempo\n' +
      'Descrizione 2: Cura dei Capelli'
  },
  {
    Objective: 'Google Ads - Negative Keyword list',
    idPrompt: 21,
    finalPrompt: 'Voglio che tu agisca come un PPC specialist. Crea una lista estensiva di negative keywords per il prodotto qui sotto.\n' +
      'Categoria prodotto: Shampoo per capelli organico e ecosostenibile\n' +
      "Descrizione prodotto: Omorfie è un brand di cura dei capelli sostenibile a base vegetale. Produce shampoo e balsami che utilizzano ingredienti organci di alta qualità e piante vegetali, senza plastiche né sostanze nocive. I prodotti non sono solo delicati sui tuoi capelli, ma anche sull'ambiente. Aiuta a risparmiare tempo e semplica la cura dei tuoi capelli.",
    Response: '\n' +
      '\n' +
      'Negative keywords:\n' +
      '-Non organico\n' +
      '-Non sostenibile\n' +
      '-Plastica\n' +
      '-Sostanze nocive\n' +
      '-Non biologico\n' +
      '-Non naturale\n' +
      '-Non ecologico\n' +
      '-Non vegano\n' +
      '-Non cruelty-free\n' +
      '-Non senza siliconi\n' +
      '-Non senza parabeni\n' +
      '-Non senza SLS\n' +
      '-Non senza SLES\n' +
      '-Non senza ftalati\n' +
      '-Non senza petrolati\n' +
      '-Non senza oli minerali\n' +
      '-Non senza coloranti\n' +
      '-Non senza profumi sintetici\n' +
      '-Non senza conservanti\n' +
      '-Non senza additivi chimici\n' +
      '-Non senza fragranze artificiali\n' +
      '-Non senza tensioattivi sintetici\n' +
      '-Non senza alcool'
  },
  {
    Objective: 'Google Ads - Responsive Display Ad',
    idPrompt: 22,
    finalPrompt: 'Voglio che tu agisca come un advertiser. Devo creare delle Responsive Display Ads per una campagna Google Ads. Suggerisci immagini, titoli e descrizioni per il prodotto qui sotto.  Proponi soluzioni creative e idee.\n' +
      'Categoria prodotto: Shampoo per capelli organico e ecosostenibile\n' +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.',
    Response: '\n' +
      '\n' +
      'Immagine 1: Una donna con capelli lunghi e lucenti, che sorride e mostra un flacone di shampoo organico.\n' +
      '\n' +
      'Titolo: "Capelli sani e luminosi con lo shampoo organico!"\n' +
      'Descrizione: "Ottieni capelli sani e luminosi con lo shampoo organico e ecosostenibile. Senza plastiche, con ingredienti organici e senza danneggiare i capelli. Prova ora!"\n' +
      '\n' +
      'Immagine 2: Una donna con capelli lunghi e lucenti, che cammina in un prato con un flacone di shampoo organico in mano.\n' +
      '\n' +
      'Titolo: "Capelli sani e luminosi con lo shampoo organico!"\n' +
      'Descrizione: "Ritrova la salute dei tuoi capelli con lo shampoo organico e ecosostenibile. Senza plastiche, con ingredienti organici e senza danneggiare i capelli. Prova ora!"'
  },
  {
    Objective: 'Video Ad Script - TikTok',
    idPrompt: 23,
    finalPrompt: `Crea lo script di un video TikTok per il prodotto qui sotto di 30 secondi. Utilizza l'hook "Why I stopped doing this and I did this instead". Dividi lo script nei paragrafi Intro, body, conclusione. Scrivi il copione del testo.\n` +
      "Descrizione prodotto: Omorfie è un brand di cura dei capelli sostenibile a base vegetale. Produce shampoo e balsami che utilizzano ingredienti organci di alta qualità e piante vegetali, senza plastiche né sostanze nocive. I prodotti non sono solo delicati sui tuoi capelli, ma anche sull'ambiente. Aiuta a risparmiare tempo e semplica la cura dei tuoi capelli.\n" +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.',
    Response: '\n' +
      '\n' +
      'Intro: \n' +
      'Ciao a tutti! Sono stata una di quelle persone che hanno provato di tutto per avere capelli sani e luminosi, ma senza successo. Ho usato prodotti che promettevano di fare miracoli, ma che alla fine mi hanno lasciato i capelli secchi e danneggiati. Ma poi ho scoperto Omorfie.\n' +
      '\n' +
      'Body: \n' +
      "Omorfie è un brand di cura dei capelli sostenibile a base vegetale. Produce shampoo e balsami che utilizzano ingredienti organici di alta qualità e piante vegetali, senza plastiche né sostanze nocive. I prodotti non sono solo delicati sui tuoi capelli, ma anche sull'ambiente. Ho smesso di usare prodotti dannosi per i miei capelli e ho iniziato a usare Omorfie. I risultati sono stati sorprendenti! I miei capelli sono più luminosi e sani che mai. Inoltre, Omorfie mi ha aiutato a risparmiare tempo, rendendo la cura dei miei capelli più semplice.\n" +
      '\n' +
      'Conclusione: \n' +
      'Se anche tu stai cercando di ottenere capelli sani e luminosi, senza danneggiarli con prodotti dannosi, allora Omorfie è la scelta giusta per te. #omorfie #capellisalutari #capelliluminosi #curaorganica #sostenibile #capellisani #capellilunghi #capelliforti #capellilucidi #capellidacurare'
  },
  {
    Objective: 'Video Ad Script - Reel PAS',
    idPrompt: 24,
    finalPrompt: 'Crea lo script di un video Reel per il prodotto qui sotto di 30 secondi. Utilizza il framework  PAS (problem, agitation, solution). Dividi lo script nei paragrafi Intro, body, conclusione. Scrivi il copione del testo.\n' +
      "Descrizione prodotto: Omorfie è un brand di cura dei capelli sostenibile a base vegetale. Produce shampoo e balsami che utilizzano ingredienti organci di alta qualità e piante vegetali, senza plastiche né sostanze nocive. I prodotti non sono solo delicati sui tuoi capelli, ma anche sull'ambiente. Aiuta a risparmiare tempo e semplica la cura dei tuoi capelli.\n" +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.',
    Response: '\n' +
      '\n' +
      'Intro:\n' +
      'Capelli sani, luminosi e senza danni? Ora è possibile grazie a Omorfie, un brand di cura dei capelli sostenibile a base vegetale.\n' +
      '\n' +
      'Body:\n' +
      "I prodotti Omorfie sono formulati con ingredienti organici di alta qualità e piante vegetali, senza plastiche né sostanze nocive. Non solo sono delicati sui tuoi capelli, ma anche sull'ambiente. Omorfie aiuta a risparmiare tempo e semplifica la cura dei tuoi capelli.\n" +
      '\n' +
      'Conclusione:\n' +
      'Omorfie è il brand di cura dei capelli sostenibile che offre prodotti di alta qualità, senza plastiche né sostanze nocive, per ottenere capelli sani, luminosi e senza danni. Prova Omorfie e scopri la differenza!'
  },
  {
    Objective: 'Video Ad Script - Reel AIDA',
    idPrompt: 25,
    finalPrompt: 'Crea lo script di un video Reel per il prodotto qui sotto di 30 secondi. Utilizza la struttura AIDA (Attention, Interest, Desire, Action). Dividi lo script nei paragrafi Intro, body, conclusione. Scrivi il copione del testo.\n' +
      "Descrizione prodotto: Omorfie è un brand di cura dei capelli sostenibile a base vegetale. Produce shampoo e balsami che utilizzano ingredienti organci di alta qualità e piante vegetali, senza plastiche né sostanze nocive. I prodotti non sono solo delicati sui tuoi capelli, ma anche sull'ambiente. Aiuta a risparmiare tempo e semplica la cura dei tuoi capelli.\n" +
      'Target Market: Donne tra i 25 e i 45 anni, che vivono in Italia e hanno bisogno di uno shampoo efficace, che non danneggia i capelli, con ingredienti organici e senza plastiche per ottenere capelli luminosi e sani.',
    Response: '\n' +
      '\n' +
      'INTRO\n' +
      "Hai i capelli secchi e fragili? Sei stanca di prodotti che danneggiano i tuoi capelli e l'ambiente? Omorfie è la soluzione che stai cercando.\n" +
      '\n' +
      'BODY\n' +
      "Omorfie è un brand di cura dei capelli sostenibile a base vegetale. Produce shampoo e balsami che utilizzano ingredienti organici di alta qualità e piante vegetali, senza plastiche né sostanze nocive. I prodotti non sono solo delicati sui tuoi capelli, ma anche sull'ambiente. Aiuta a risparmiare tempo e semplica la cura dei tuoi capelli. Omorfie fornisce una cura dei capelli di qualità, con ingredienti naturali che non danneggiano i capelli. I prodotti sono testati dermatologicamente e sono privi di sostanze chimiche nocive. La linea di prodotti Omorfie aiuterà a mantenere i tuoi capelli sani, luminosi e morbidi.\n" +
      '\n' +
      'DESIRE\n' +
      "Omorfie è la scelta giusta per le donne che vogliono prendersi cura dei loro capelli in modo sostenibile. Omorfie offre prodotti di qualità, con ingredienti naturali che non danneggiano i capelli e l'ambiente. I prodotti sono testati dermatologicamente e privi di sostanze chimiche nocive.\n" +
      '\n' +
      'ACTION\n' +
      'Non aspettare più! Prova i prodotti Omorfie per una cura dei capelli sostenibile e di qualità. Per capelli luminosi, sani e morbidi.\n' +
      '\n' +
      'CONCLUSIONE\n' +
      'Omorfie è la scelta giusta per le donne che vogliono prendersi cura dei loro capelli in modo sostenibile. Prova i prodotti Omorfie per una cura dei capelli di qualità e sostenibile. Per capelli luminosi, sani e morbidi.'
  }
]);
const tableRef = useRef(null);
const [open,setOpen] = useState(false);
const [nomeProgetto,setNomeProgetto] = useState("");





const [prompt,setPrompt] = useState([

{id:1,prompt:"Voglio che tu agisca come un market researcher con molta esperienza. Scrivi una lista dei 3 principali competitor nella categoria nominata qui sotto. e inserisci l'URL del loro sito web, instagram, facebook, tiktok, pinterest, linkedin. ",
ref1:"prodCateg",ref2:"targetMarket",result:""},
{id:2,prompt:"Ora fai la tua migliore ipotesi sulle 3 principali unique selling proposition del prodotto di ogni brand. Sii quanto più dettagliato possibile.",
ref1:"",ref2:"",result:""},


]);


const generateDocFirstStep = async(e) => {

  setOpen(true);

  let result = await api.post("/generateAI",{dataToEdit:dataToEdit,prompt:prompt,automationData:automationData})

  setResult(result.data.results)

setOpen(false)

 
 }

 const generateDoc = async(e) => {

  setOpen(true);

  let result = await api.post("/generateAI",{nomeProgetto:nomeProgetto,username:sessionStorage.getItem("username"),dataToEdit:dataToEdit,prompt:prompt,automationData:automationData})

  setResult(result.data.results)

setOpen(false)

 
 }




const handleChange = (e) => {


let dTE = {...dataToEdit}
dTE[e.target.name] = e.target.value;
setDataToEdit(dTE);


}


const { onDownload } = useDownloadExcel({
  currentTableRef: tableRef.current,
  filename: 'Business Plan',
  sheet: 'Results'
})



useEffect(() => {
  // è un loop qui carico i dati
  const fetchData = async () => {
    try {
      //FIELDS
      let response = await api.get("documentFields", {
       /*headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },*/
        params: { dType: 1},
      });
      setAutomationData(response.data.data);
      let obj = {};
      for(let i=0;i<response.data.length;i++){
        let field = response.data[i].Field.replaceAll(" ","");
      
        obj[field] = "";
   
      }


      setDataToEdit(obj)



      let responsePrompts = await api.get("prompts", {
        /*headers: {
           Authorization: `Bearer ${localStorage.getItem("jwt")}`,
         },*/
         params: { dType: 1},
       });
       setPrompt(responsePrompts.data.data);
    } catch (error) {
      console.error(error.message);
    }
  };

  fetchData();
}, []);





  return (
    <div>
      <Backdrop
  sx={{ color: '#fff',zIndex:50}}
  open={open}

>
  
  Prompts inviati! Il file verrà inviato alla tua email. <br/>
<button>TORNA AI MIEI PROGETTI</button>
</Backdrop>



      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Business Profile</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
      
    <Grid container spacing={2} >
 <Grid item xs={12}>
    <TextField fullWidth id="outlined-basic" variant="outlined"
              label="Nome Progetto"
              value={nomeProgetto}
              onChange={(e) => setNomeProgetto(e.target.value)}
              /> </Grid>

        {automationData.map(el => 
            <Grid item xs={6}
             
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
             
  
              <TextField fullWidth id="outlined-basic" label={el.Field} variant="outlined"
              name={el.Field.replaceAll(" ","")}
              value={dataToEdit[el.Field.replaceAll(" ","")]}
              onChange={handleChange}
              />


           
      </Grid>
            

        )}
 </Grid>
          </Typography>
        </AccordionDetails>
      </Accordion>

 
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Prompt Library</Typography>
        </AccordionSummary>
        <AccordionDetails>
       <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Phase</TableCell>
            <TableCell >Objective</TableCell>
            <TableCell >Input</TableCell>
            <TableCell >Reference Point 1</TableCell>
            <TableCell >Reference Point 2</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {prompt.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >

              <TableCell >{row.Phase}</TableCell>
              <TableCell >{row.Objective}</TableCell>
              <TableCell >{row.Input}</TableCell>
              {dataToEdit && row.RefPoint1?(row.RefPoint1Type ?
              <TableCell >
                Campo popolato con risposta a riga <b>{prompt.filter(el => el.id == row.RefPoint1)[0]?.Objective}</b>

              </TableCell>:
              <TableCell>
                {
                dataToEdit[automationData.filter(el => el.id == row.RefPoint1)[0]?.Field.replaceAll(" ","")]
                } 
                </TableCell>
                ):<TableCell ></TableCell>}
              {dataToEdit && row.RefPoint2?(row.RefPoint2Type ?
              <TableCell > Campo popolato con risposta a riga <b>{prompt.filter(el => el.id == row.RefPoint2)[0]?.Objective}</b>

                
              </TableCell>
              :
              <TableCell>
                {
                dataToEdit[automationData.filter(el => el.id == row.RefPoint2)[0]?.Field.replaceAll(" ","")]
                } 
                </TableCell>
                ):<TableCell ></TableCell>}
              <TableCell >{row.result}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </AccordionDetails>
      </Accordion>
    {
      result?
      <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Typography>Output</Typography>
      </AccordionSummary>
      <AccordionDetails>
      <Button  className="ButtonMiller" variant="contained" onClick={onDownload}> Export excel </Button>
     <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table" ref={tableRef}>
      <TableHead>
        <TableRow>
          <TableCell >Objective</TableCell>
          <TableCell >Final prompt</TableCell>
          <TableCell >Output</TableCell>
  
        </TableRow>
      </TableHead>
      <TableBody>
        {result.map((row) => (
          <TableRow
            key={row.idPrompt}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >

         
            <TableCell >{row.Objective}</TableCell>
            <TableCell style={{whiteSpace: "pre-wrap"}}>{row.finalPrompt}</TableCell>
            <TableCell style={{whiteSpace: "pre-wrap"}}>{row.Response}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
      </AccordionDetails>
    </Accordion>
      
      :""


    }
    <Button sx={{marginTop:"10px",float:"right"}} title='Genera' onClick={generateDoc} variant="contained" className="ButtonMiller"> GENERA</Button>
  
    </div>
  );
}

export default Automation;