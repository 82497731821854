import React, { useEffect, useState, useCallback, useMemo } from "react";
//BACKGROUND EFFECT
import Particles, { initParticlesEngine } from "@tsparticles/react";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "@tsparticles/slim";

export const BgEffect = () => {
  const [init, setInit] = useState(false);
  const [screenSize, getDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [point, setPoint] = useState((window.innerWidth * window.innerHeight)/10000);
  

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
      // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
      // starting from v2 you can add only the features you need reducing the bundle size
      //await loadAll(engine);
      //await loadFull(engine);
      await loadSlim(engine);
      //await loadBasic(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const setDimension = () => {
    getDimension({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    setPoint((window.innerWidth * window.innerHeight)/10000)
  };
  
  useEffect(() => {
    window.addEventListener("resize", setDimension);
  }, [screenSize]);

  const particlesLoaded = (container) => {
    //console.log(container);
  };
  const options = useMemo(
    () => ({
      background: {
        color: "#060044",
      },
      particles: {
        number: {
          value: point
        },
        links: {
          distance: 150,
          enable: true,
          triangles: {
            //permette la creazione dei triangoli semi opachi
            enable: true,
            opacity: 0.1,
          },
        },
        move: {
          enable: true,
          speed: 1,
        },
        size: {
          value: 1,
        },
        shape: {
          type: "circle",
        },
      },
      interactivity: {
        //detectOn: "window",
        enable: false,
      },
    }),
    []
  );

  return (
    <>
      {init ? (
        <>
          <Particles
            id="tsparticles"
            particlesLoaded={particlesLoaded}
            options={options}
          />
        </>
      ) : (
        <div className="bg-[#060044]"></div>
      )}
    </>
  );
};
