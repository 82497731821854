import React, { useState, useEffect } from "react";
import api from "./api/api";
import { useNavigate } from "react-router-dom";
import { DataGridPro, itIT, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import { Circle, TonalitySharp } from "@mui/icons-material";
import * as XLSX from "xlsx";
import { FaFileImport, FaFileMedical, FaCheck, FaRegTrashAlt } from "react-icons/fa";
import { Modal } from "./utility/Modal";
import CustomAutocomplete from "./utility/Autocomplete";
import { toast } from "react-toastify";
import { IoMdCloudUpload } from "react-icons/io";
import { Tooltip, Grid } from "@mui/material";
import { IoCheckmarkOutline } from "react-icons/io5";
import { FaCoins } from "react-icons/fa";

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<Grid container direction="row" alignItems="center" justifyContent="flex-start" sx={{ columnGap: 2, margin: { xs: 2, md: 1 } }}>
				<GridToolbarQuickFilter
					placeholder="Cerca..."
					sx={{
						"& .MuiInputBase-input": {
							color: "white",
						},
						"& .MuiInputBase-root": {
							color: "white",
						},
					}}
				/>
			</Grid>
		</GridToolbarContainer>
	);
}

const Progetti = () => {
	const [progetti, setProgetti] = useState([]);
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	const [documenti, setDocumenti] = useState([]);
	const [documentoSel, setDocumentoSel] = useState({});
	const [error, setError] = useState(false);
	const [selectedIds, setSelectedIds] = useState([]);
	const [openElimina, setOpenElimina] = useState(false);
	const rank = Number(sessionStorage.getItem("rank"));
	const [tokens, setTokens] = useState(0);
	const [loading, setLoading] = useState(true);
	const [expiry, setExpiry] = useState(null);

	const getFields = async () => {
		let res = await api.get("documents", {
			params: { username: sessionStorage.getItem("username") },
		});
		setDocumenti(res.data.data);
	};

	const fetchUserInfo = async () => {
		//let responseProjectDetail = await api.get(`progetti/${id}`);

		try {
			const res = await api.get("users", {
				params: { username: sessionStorage.getItem("username") },
			});
			setTokens(res.data[0].token);
			if (res.data[0].token_expiration) {
				setExpiry(new Date(res.data[0].token_expiration));
			}

			setLoading(false);
		} catch (error) {
			console.error("Failed to fetch user info:", error);
		}
	};

	const columns = [
		{
			field: "Progetto",
			headerName: "Progetto",
			flex: 1,
			align: "center",
			headerAlign: "center",
		},
		{
			field: "Stato",
			headerName: "Stato",
			width: 300,
			align: "center",
			headerAlign: "center",
			renderCell: (params) => {
				if (params.row.Completato === 1) {
					return <Circle sx={{ color: "green" }} />;
				} else {
					return <Circle sx={{ color: "red" }} />;
				}
			},
		},
	];

	useEffect(() => {
		fetchUserInfo();
		loadProgetti();
	}, []);

	const loadProgetti = async () => {
		let res = await api.get("progetti", {
			params: { username: sessionStorage.getItem("username") },
		});
		setProgetti(res.data.data);
	};

	// Function to handle file import
	const handleFileImport = (event) => {
		const file = event.target.files[0];
		const reader = new FileReader();

		reader.onload = async (e) => {
			const data = new Uint8Array(e.target.result);
			const workbook = XLSX.read(data, { type: "array" });

			// Assuming the first sheet is the one we convert
			const sheetName = workbook.SheetNames[0];
			const sheet = workbook.Sheets[sheetName];
			const jsonData = XLSX.utils.sheet_to_json(sheet);

			// Subtract token and navigate (only if rank is 0)
			if (rank === 0) {
				try {
					navigate("/Marketing", { state: { importData: jsonData, documentType: documentoSel?.id } });
				} catch (error) {
					toast.error("Token Esauriti. Non puoi creare nuovi progetti.");
				}
			} else {
				// For administrators (rank 1), no token logic applies
				navigate("/Marketing", { state: { importData: jsonData, documentType: documentoSel?.id } });
			}
		};

		reader.readAsArrayBuffer(file);
	};

	const handleModal = async () => {
		await getFields();
		setOpen(true);
	};

	const handleClickImporta = () => {
		// Only apply token checks if the user is a normal user (rank 0)
		if (rank === 0) {
			if (tokens > 0 || expiry <= new Date()) {
				if (documentoSel.id) {
					document.getElementById("excelImport").click();
				} else {
					setError(true);
					toast.error("Campo obbligatorio");
				}
			} else {
				toast.error("Token Esauriti. Non puoi creare nuovi progetti.");
			}
		} else {
			// For administrators (rank 1), skip token checks
			if (documentoSel.id) {
				document.getElementById("excelImport").click();
			} else {
				setError(true);
				toast.error("Campo obbligatorio");
			}
		}
	};

	const handleCloseModal = () => {
		setOpen(false);
		setDocumentoSel({});
		setError(false);
	};

	const handleElimina = async () => {
		let successMessage = selectedIds.length > 1 ? "Progetti eliminati con successo" : "Progetto eliminato con successo";
		let errorMessage = selectedIds.length > 1 ? "Errore durante l'Eliminazione dei Progetti" : "Errore durante l'Eliminazione del Progetto";
		try {
			await api.delete("/progetti", { data: { ids: selectedIds } });
			toast.success(successMessage);
			setOpenElimina(false);
			setSelectedIds([]);
		} catch (error) {
			toast.error(errorMessage);
			console.error(error);
		}
		await loadProgetti();
	};

	return (
		<>
			<div className="flex flex-wrap w-full gap-4">
				<div className="flex w-full justify-between items-center gap-2 ml-2">
					{/* Show token information only for regular users (rank = 0) */}
					{rank === 0 && !loading && (
						<div className="text-white font-semibold flex items-center">
							<FaCoins className="mr-2 text-white" />
							Token Rimasti:&nbsp;
							<span className={tokens <= 1 ? "text-red-500" : "text-white"}>{tokens}</span>
						</div>
					)}

					{/* Buttons Container */}
					<div className="flex justify-end gap-2 ml-auto">
						{" "}
						{/* Ensure buttons align to the right */}
						{/* Show delete button only for admins (rank = 1) */}
						{rank === 1 && selectedIds.length > 0 && (
							<button
								onClick={() => {
									setOpenElimina(true);
								}}
								type="button" // Change type to button
								className="button-prophet-delete"
							>
								<FaRegTrashAlt color="white" />
								Elimina
							</button>
						)}
						{/* Import button */}
						<button
							type="button" // Change type to button
							onClick={() => {
								const currentDate = new Date(); // Get the current date
								if (tokens === 0 && rank === 0) {
									toast.error("Token Esauriti");
								} else if (expiry && currentDate >= expiry) {
									toast.error("Token scaduti");
								} else {
									handleModal();
								}
							}}
							className={`${(tokens === 0 && rank === 0) || (expiry && new Date() >= expiry) ? "button-prophet-disabled" : "button-prophet"}`}
						>
							<FaFileImport color="white" />
							Importa da file Excel
						</button>
						<input id="excelImport" type="file" className="hidden" accept=".xls,.xlsx" onChange={handleFileImport} />
						{/* Create new project button */}
						<button
							type="button" // Change type to button
							onClick={() => {
								const currentDate = new Date(); // Get the current date
								if (tokens === 0 && rank === 0) {
									toast.error("Token Esauriti");
								} else if (expiry && currentDate >= expiry) {
									toast.error("Token scaduti");
								} else {
									navigate("/Marketing");
								}
							}}
							className={`${(tokens === 0 && rank === 0) || (expiry && new Date() >= expiry) ? "button-prophet-disabled" : "button-prophet"}`}
						>
							<FaFileMedical color="white" />
							Crea nuovo progetto
						</button>
					</div>
				</div>

				<div className="w-full">
					<div className="w-full h-[70vh] 2xl:h-[75vh] bg-white/20 shadow-lg ring-1 ring-black/5 backdrop-blur-md rounded-md ">
						<DataGridPro
							localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
							rows={progetti}
							columns={columns}
							initialState={{
								pagination: {
									paginationModel: {
										pageSize: 5,
									},
								},
							}}
							slots={{
								toolbar: CustomToolbar,
							}}
							onRowDoubleClick={(row) => {
								navigate("/DettaglioProgetto", {
									state: { ID: row?.id, nomeProgetto: row.row.Progetto },
								});
							}}
							checkboxSelection
							disableRowSelectionOnClick
							getRowId={(r) => r.ID}
							onRowSelectionModelChange={(ids) => {
								setSelectedIds(ids);
							}}
						/>
					</div>
					<Modal
						open={openElimina}
						handleClose={() => {
							setOpenElimina(false);
						}}
					>
						<div className="flex flex-col">
							<div className="w-full my-2">
								<h1 className="dark:text-white w-full text-center mb-4 font-bold text-xl">Sei sicuro di voler cancellare i progetti selezionati?</h1>
								<div className="flex text-white flex-col gap-2 justify-center overflow-auto max-h-[60vh] p-2">
									{selectedIds.length > 0 ? (
										selectedIds.map((id) => {
											const progetto = progetti.find((p) => p.ID === id);
											return progetto ? (
												<li key={id} className="text-center my-1">
													{progetto.Progetto + " - " + id}
												</li>
											) : null;
										})
									) : (
										<div className="text-gray-500 text-center">Nessun progetto selezionato</div>
									)}
								</div>

								<div className="flex justify-end items-end">
									<button type="submit" className="button-prophet-reverse mt-8" onClick={handleElimina}>
										<IoCheckmarkOutline className="text-3xl mr-1" />
										Conferma
									</button>
								</div>
							</div>
						</div>
					</Modal>
					<Modal open={open} handleClose={handleCloseModal}>
						<div className=" flex flex-col">
							<div className="w-full my-2">
								<CustomAutocomplete
									placeholder="Seleziona Tipo del documento"
									suggestions={documenti}
									fields={["Tipologia"]}
									keyField="id"
									value={documentoSel}
									error={error}
									onChange={(e) => {
										setError(false);
										const selectedId = e;
										const selectedDocumento = documenti.find((doc) => doc.id === selectedId);
										setDocumentoSel(selectedDocumento);
									}}
									id="Tipologia"
									label="Tipologia Documento"
									name="Tipologia"
								/>
								<div className="flex justify-end items-end">
									<button type="submit" className="button-prophet-reverse mt-8" onClick={handleClickImporta}>
										<IoMdCloudUpload className="text-3xl mr-1" />
										Importa
									</button>
								</div>
							</div>
						</div>
					</Modal>
				</div>
			</div>
		</>
	);
};

export default Progetti;
