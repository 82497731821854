import React, { useEffect, useState, useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CircularProgress, Stepper, Step, StepButton, Typography, Grid, FormControlLabel, Checkbox, Autocomplete, TextField } from "@mui/material";
import api from "./api/api";
import { useLocation, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaArrowRight, FaCheck, FaFileExport, FaPlus } from "react-icons/fa";
import { FaCircleArrowUp, FaRepeat } from "react-icons/fa6";
import CustomAutocomplete from "./utility/Autocomplete";
import { Fields } from "./DettaglioDocumento";
import { toast } from "react-toastify";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";

const steps = ["Compila i campi indicati", "Seleziona i prompts", "Competitors"];

const Marketing = () => {
	const [activeStep, setActiveStep] = useState(0);
	const [completed, setCompleted] = useState({});
	const [automationData, setAutomationData] = useState([]);
	const [dataToEdit, setDataToEdit] = useState([]);
	const [result, setResult] = useState([]);
	const [resultFinale, setResultFinale] = useState([]);
	const tableRef = useRef(null);
	const [aggiunte, setAggiunte] = useState("");
	const [nomeProgetto, setNomeProgetto] = useState("");
	const [idProgetto, setIdProgetto] = useState(null);
	const location = useLocation();
	const [importedCompetitors, setImportedCompetitors] = useState([]);
	const myRef = useRef(null);
	const [required, setRequired] = useState(false);

	const [spin, setSpin] = useState(false);

	const [phases, setPhases] = useState([]);

	const [documenti, setDocumenti] = useState([]);
	const [documentoSel, setDocumentoSel] = useState(null);

	const [openField, setOpenField] = useState(false);

	const rank = Number(sessionStorage.getItem("rank"));

	const navigate = useNavigate();

	const [prompt, setPrompt] = useState([
		{
			id: 1,
			prompt:
				"Voglio che tu agisca come un market researcher con molta esperienza. Scrivi una lista dei 3 principali competitor nella categoria nominata qui sotto. e inserisci l'URL del loro sito web, instagram, facebook, tiktok, pinterest, linkedin. ",
			ref1: "prodCateg",
			ref2: "targetMarket",
			result: "",
		},
		{
			id: 2,
			prompt: "Ora fai la tua migliore ipotesi sulle 3 principali unique selling proposition del prodotto di ogni brand. Sii quanto più dettagliato possibile.",
			ref1: "",
			ref2: "",
			result: "",
		},
	]);
	const LLModels = [
		{ id: 0, name: "GPT (OpenAI)" },
		{ id: 1, name: "Gemini (Google)" },
	];
	//Default value is gpt
	const [llmSel, setLLMType] = useState(0);

	const generateDoc = async (promptDaInviare) => {
		let categorieSel = phases.filter((p) => p.Active).map((el) => el.Phase);
		let promptDaTenere = promptDaInviare.filter((p) => categorieSel.indexOf(p.Phase) !== -1);

		let res = await api.post("/generateAI", {
			idProgetto: idProgetto,
			username: sessionStorage.getItem("username"),
			dataToEdit: dataToEdit,
			prompt: promptDaTenere,
			automationData: automationData,
			stepToSkip: 0,
			email: sessionStorage.getItem("email"),
			send: true,
			llmSel,
			nomeProgetto,
			rank,
		});
		setResultFinale(res.data.results);
	};
	const generateDocFirstStep = async () => {
		let result = await api.post("/generateAI", {
			idProgetto: idProgetto,
			username: sessionStorage.getItem("username"),
			dataToEdit: dataToEdit,
			prompt: [prompt[0]],
			automationData: automationData,
			email: sessionStorage.getItem("email"),
			send: false,
			llmSel,
			nomeProgetto,
			rank,
		});

		setResult(result.data.results);

		if (result.data.results) {
			let p = [...prompt];
			p[0].result = result.data.results[0].Response;
			setPrompt(p);
		}
	};
	const handleChange = (e, index, valueFromAutoComplete = null) => {
		let updatedData = [...automationData];

		if (valueFromAutoComplete) {
			//Autocomplete case
			//console.log("Autocomplete value: ", valueFromAutoComplete);
			updatedData[index].Value = valueFromAutoComplete;
		} else if (e) {
			//regular input change
			//console.log("Input value: ", e.target.value);
			updatedData[index].Value = e.target.value;
		}

		setAutomationData(updatedData);
	};

	const handleAutocompleteChange = (index) => (event, value) => {
		const finalValue = value || (event && event.target ? event.target.value : "");
		handleChange(null, index, finalValue);
	};

	const createProject = async () => {
		try {
			// First, create the project
			let res = await api.post("/progetti", {
				nomeProgetto: nomeProgetto,
				username: sessionStorage.getItem("username"),
			});

			// Store the project ID after creation
			setIdProgetto(res.data.message.insertId);

			// Insert automation data related to the project
			await api.post("/aiData", {
				automationData: automationData,
				idProgetto: res.data.message.insertId,
			});

			// After successfully creating the project, subtract one token
			await api.post("/users", {
				username: sessionStorage.getItem("username"),
			});

			toast.success("Progetto creato con successo!");
		} catch (error) {
			console.error("Error creating project or subtracting token:", error);
			toast.error("Errore durante la creazione del progetto o sottrazione del token.");
		}
	};

	const getFileds = async (value) => {
		setDocumentoSel(value);
		let response = await api.get("documentFields", {
			params: { dType: value },
		});
		setAutomationData(response.data.data);

		// If it is not Business Profile (dType !== 1)
		if (value !== 1) {
			let responsePrompts = await api.get("prompts", {
				params: { dType: value },
			});
			setPrompt(responsePrompts.data.data);

			// Create phases with notes
			const phasesWithNotes = responsePrompts.data.data.reduce((acc, x) => {
				const existingPhase = acc.find((item) => item.Phase === x.Phase);
				if (!existingPhase) {
					acc.push({ Phase: x.Phase, Active: true, Note: x.note });
				}
				return acc;
			}, []);

			setPhases(phasesWithNotes);
		} else {
			// Create data to edit
			let obj = {};
			for (let i = 0; i < response.data.data.length; i++) {
				let field = response.data.data[i].Field;
				obj[field] = "";
			}
			setDataToEdit(obj);

			// Fetch prompts for Business Profile
			let responsePrompts = await api.get("prompts", {
				params: { dType: value },
			});
			setPrompt(responsePrompts.data.data);

			// Create phases for Business Profile
			let pses = [...new Set(responsePrompts.data.data.map((x) => x.Phase))];
			setPhases(pses.map((el) => ({ Phase: el, Active: true })));
		}
	};

	const normalizeField = (fieldName) => {
		return fieldName
			.toLowerCase() // Convert to lowercase
			.trim() // Remove leading/trailing spaces
			.replace(/\s+/g, " "); // Replace multiple spaces with a single space
	};

	const fetchData = async () => {
		try {
			let response = await api.get("documentFields", {
				params: {
					dType: location?.state?.documentType ? location.state.documentType : 1,
				},
			});

			let res = await api.get("documents", {
				params: { username: sessionStorage.getItem("username") },
			});

			if (location?.state?.documentType) {
				setDocumentoSel(location?.state?.documentType);
			}

			setDocumenti(res.data.data);

			if (location.state) {
				const keyInfoMap = location.state?.importData.reduce((map, obj) => {
					const key = normalizeField(obj.Area); // Normalize the field name
					map[key] = {
						keyInfo: obj["Key Info"],
						suggerimenti: obj["Suggerimenti"],
					};
					return map;
				}, {});

				let p = location.state?.importData[0];
				setNomeProgetto(p["Key Info"]);

				// Update automationData
				const updatedAutomationData = response.data.data.map((obj) => {
					const normalizedField = normalizeField(obj.Field); // Normalize the field name
					return {
						...obj,
						// Match normalized field name to keyInfoMap
						Value: keyInfoMap[normalizedField]?.keyInfo || obj.Value,
						Suggerimenti: keyInfoMap[normalizedField]?.suggerimenti || "",
					};
				});

				setAutomationData(updatedAutomationData);
				const competitors = Object.keys(keyInfoMap)
					.filter((key) => key.startsWith("competitor "))
					.map((key) => {
						const competitor = keyInfoMap[key];
						return {
							keyInfo: competitor.keyInfo, // assuming you want to display keyInfo
							suggerimenti: competitor.suggerimenti, // assuming you want to display suggerimenti
						};
					})
					.filter((value) => value.keyInfo !== undefined && value.keyInfo !== "");
				setImportedCompetitors(competitors);
			} else {
				setAutomationData(response.data.data); // Set default data if importData is not available
			}

			let obj = {};
			for (let i = 0; i < response.data.data.length; i++) {
				let field = response.data.data[i].Field;
				obj[field] = "";
			}
			setDataToEdit(obj);

			let responsePrompts = await api.get("prompts", {
				params: {
					dType: location?.state?.documentType ? location.state.documentType : 1,
				},
			});
			setPrompt(responsePrompts.data.data);

			const phasesWithNotes = responsePrompts.data.data.reduce((acc, x) => {
				const existingPhase = acc.find((item) => item.Phase === x.Phase);
				if (!existingPhase) {
					acc.push({ Phase: x.Phase, Active: true, Note: x.note });
				}
				return acc;
			}, []);

			setPhases(phasesWithNotes);
		} catch (error) {
			console.log(error.message);
		}
	};

	useEffect(() => {
		fetchData();
	}, []);

	const totalSteps = () => {
		return steps.length;
	};
	const completedSteps = () => {
		return Object.keys(completed).length;
	};
	const isLastStep = () => {
		return activeStep === totalSteps() - 1;
	};

	const allStepsCompleted = () => {
		return completedSteps() === totalSteps();
	};

	const handleNext = () => {
		const newActiveStep =
			isLastStep() && !allStepsCompleted()
				? // It's the last step, but not all steps have been completed,
				  // find the first step that has been completed
				  steps.findIndex((step, i) => !(i in completed))
				: activeStep + 1;

		if (newActiveStep === 1) {
			if (nomeProgetto === "" || nomeProgetto === null) {
				setRequired(true);
				myRef.current.scrollIntoView({
					behavior: "smooth",
				});
				return toast.error("Nome progetto obbligatorio!");
			} else if (documentoSel === null) {
				return toast.error("Per favore, seleziona la tipologia del documento per procedere!");
			} else {
				createProject();
			}
		}
		setActiveStep(newActiveStep);
		if (newActiveStep === 2) {
			// If no competitor provided when doing import just normal casual
			if (importedCompetitors.length > 0) {
				// Very simple way of replicating the returned object from gendocfirststep but works
				let p = [...prompt];

				// Combine the competitor names into a string
				const combinedString = importedCompetitors
					.map((competitor, index) => `${index + 1}. ${competitor.keyInfo}`) // Use the keyInfo property
					.join("\n");

				p[0].result = combinedString;

				// Added this because when importing competitors we did not insert it into db
				p[0].premade = 1;

				setPrompt(p);

				let arr = [];
				let obj = {
					Objective: "Competitor Overview",
					Response: combinedString,
					finalPrompt: `Voglio che tu agisca come un market researcher con molta esperienza. Forniscimi i nomi dei 3 principali competitor nella Categoria Prodotto: ${automationData[5]?.Value} per Area Geografica: ${automationData[15]?.Value}. Target Market: ${automationData[4]?.Value}`,
					idPrompt: 9,
					Phase: "Market Overview",
				};
				arr.push(obj);
				setResult(arr);
			} else {
				generateDocFirstStep();
			}
		}

		if (newActiveStep === 3) {
			if (aggiunte !== "") {
				let p = [...prompt];
				let obj = {
					id: 99999,
					Input: "Come mai manca il brand " + aggiunte + " nelle categorie sotto indicate? \n {{CategoriaProdotto}}",
					RefPoint1: "1",
					RefPoint2: null,
					result: "",
					Objective: "Missing Competitors",
					Phase: "Market Overview",
				};

				p.splice(1, 0, obj);

				generateDoc(p);
			} else {
				generateDoc(prompt);
			}
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStep = (step) => () => {
		if (nomeProgetto === "" || nomeProgetto === null) {
			setRequired(true);
			toast.error("Nome progetto obbligatorio!");
			myRef.current.scrollIntoView({
				behavior: "smooth",
			});
		} else {
			setRequired(false);
			setActiveStep(step);
		}
	};

	const handleComplete = async () => {
		if (nomeProgetto === "" || nomeProgetto === null) {
			setRequired(true);
			myRef.current.scrollIntoView({
				behavior: "smooth",
			});
			return toast.error("Nome progetto obbligatorio!");
		}
		if (documentoSel === null) {
			return toast.error("Per favore, seleziona la tipologia del documento per procedere!");
		}

		//DEPRECATED POSSIBLY COULD BE DELETED WE USE TOKENS NOW AND GET TO THIS STEP ONLY IF ITS VALID SO NO NEED TO CONTROL
		/* try {
			let res = await api.get("progetti/beta/avaibleproj", {
				params: { username: sessionStorage.getItem("username") },
			});
		} catch {
			toast.error("Attenzione, hai finito il numero di progetti che puoi generare nella beta");
			return;
		} */

		const newCompleted = completed;
		newCompleted[activeStep] = true;
		setCompleted(newCompleted);
		handleNext();
	};

	const handleReset = () => {
		setActiveStep(0);
		setCompleted({});
	};

	const handleCheckboxClick = (index) => {
		let pes = [...phases];
		pes[index].Active = !pes[index].Active;
		setPhases(pes);
	};

	const renderElement = (index) => {
		switch (index) {
			case 0:
				return (
					<div className="grid grid-cols-2 w-full flex-wrap gap-2 items-center justify-center">
						<div className="col-span-1">
							<label for="text" className="label-text" ref={myRef}>
								Nome Progetto<span className="font-thin">*</span>
							</label>

							<input
								type="text"
								className={required ? "input-text-error" : "input-text"}
								placeholder={"Inserire Nome Progetto"}
								value={nomeProgetto}
								onChange={(e) => setNomeProgetto(e.target.value)}
							/>
						</div>

						<div className="col-span-1">
							<CustomAutocomplete
								placeholder="Seleziona Tipologia Documento"
								suggestions={documenti}
								fields={["Tipologia"]}
								keyField="id"
								value={documentoSel}
								id="Tipologia"
								label="Tipologia Documento *"
								name="Tipologia"
								onChange={(e) => getFileds(e)}
							/>
						</div>

						<div className="col-span-1 mb-2">
							<CustomAutocomplete
								placeholder="Seleziona Modello AI"
								suggestions={LLModels}
								fields={["name"]}
								keyField="id"
								value={llmSel}
								id="id"
								label="Modello AI *"
								name="name"
								onChange={(e) => {
									setLLMType(e);
								}}
							/>
						</div>
						{/* Dummy div to occupy remaining space in the row */}
						<div className="col-span-1 mb-2"></div>

						{/* <div className="h-1 col-span-2 bg-white/30 mt-4"></div> */}
						{documentoSel && <div className="col-span-2 mt-4 label-text-xl">FIELDS - {documenti.filter((doc) => doc.id === documentoSel)[0]?.Tipologia}</div>}
						{automationData.map((el, index) => (
							<div className="col-span-2 lg:col-span-1 mb-2" key={index}>
								<Tooltip title={<span style={{ fontSize: "1rem" }}>{el.Suggerimenti}</span>} TransitionComponent={Zoom} placement="top" arrow>
									<label htmlFor={el.Field} className="label-text text-xl">
										{el.Field}
									</label>
								</Tooltip>

								{/* Conditional rendering for "Tone Of Voice" */}
								{el.Field === "Tone Of Voice" ? (
									<Autocomplete
										id="combo-box-demo"
										freeSolo // Allows the input of custom values
										options={[
											"Istituzionale – Professionale e formale, adatto per comunicazioni ufficiali e aziendali.",
											"Amichevole – Informale e caloroso, con un linguaggio che crea vicinanza con il pubblico.",
											"Ironico – Allegro e umoristico, perfetto per catturare l'attenzione con leggerezza.",
											"Empatico – Attento e sensibile, per creare una connessione emotiva con l’audience.",
											"Motivazionale – Incoraggiante e ispiratore, ideale per messaggi positivi e di crescita.",
											"Tecnico – Dettagliato e specifico, perfetto per comunicare informazioni complesse in modo chiaro.",
											"Giovanile – Fresco e dinamico, adatto a un pubblico giovane, con un linguaggio trendy.",
											"Provocatorio – Coraggioso e diretto, pensato per sfidare opinioni o rompere gli schemi.",
											"Seducente – Coinvolgente e affascinante, utilizzato per attrarre e persuadere con eleganza.",
											"Narrativo – Raccontare storie e esperienze, coinvolgendo il pubblico attraverso storytelling.",
											"Affermativo – Sicuro di sé e determinato, ideale per comunicare con autorevolezza e convinzione.",
											"Minimalista – Semplice e diretto, perfetto per comunicazioni concise ed essenziali.",
											"Sostenibile – Attento e consapevole, per campagne che pongono l'accento su temi ambientali o sociali.",
											"Collaborativo – Inclusivo e partecipativo, che invita il pubblico a contribuire e interagire.",
											"Popolare – Spontaneo e colloquiale, pensato per essere accessibile e vicino al linguaggio comune.",
										]}
										className="w-full text-white"
										value={el.Value ? el.Value : null} // Bind the imported value from automationData to the Autocomplete value
										onChange={handleAutocompleteChange(index)} // Use the new separate change handler
										onBlur={(event) => {
											const value = event.target.value; // Get the current value
											handleChange(event, index, value); // Update the value when the input loses focus
										}}
										PaperComponent={({ children }) => <Paper elevation={0}>{children}</Paper>}
										renderInput={(params) => (
											<TextField
												{...params}
												sx={{
													color: "white",
												}}
											/>
										)}
									/>
								) : (
									<input type="text" className="input-text" placeholder={`Inserire ${el.Field}`} name={el.Field} value={el.Value} onChange={(e) => handleChange(e, index)} />
								)}
							</div>
						))}
					</div>
				);
			case 1:
				return (
					<Grid container spacing={2}>
						{phases.map((phase, index) => (
							<Grid item xs={12} key={index}>
								<Accordion
									sx={{
										backgroundColor: "transparent",
										borderWidth: 1,
										borderColor: "whitesmoke",
										color: "white",
									}}
									{...(rank === 0 ? { expanded: false } : rank === 1 ? {} : {})} // Spread only when rank is 0
								>
									<AccordionSummary
										{...(rank === 1 ? { expandIcon: <ExpandMoreIcon /> } : {})} // Only include expandIcon when rank is 1
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										<FormControlLabel
											control={<Checkbox checked={phase.Active} onClick={() => handleCheckboxClick(index)} disabled={phase.Phase === "Market Overview"} />}
											label={
												<div className="flex flex-col">
													<span className="text-white text-sm sm:text-base md:text-lg font-light">{phase.Phase}</span>
													{phase.Note && <span className="text-gray-300 text-xs sm:text-sm md:text-base">{phase.Note}</span>}
												</div>
											}
										/>
									</AccordionSummary>
									<AccordionDetails>
										<Table sx={{ tableLayout: "fixed" }} aria-label="simple table" ref={tableRef}>
											<TableHead>
												<TableRow>
													<TableCell>
														<span className="text-white text-sm sm:text-base md:text-lg font-light">Objective</span>
													</TableCell>
													<TableCell>
														<span className="text-white text-sm sm:text-base md:text-lg font-light">Final prompt</span>
													</TableCell>
												</TableRow>
											</TableHead>
											{documentoSel === 1
												? prompt
														.filter((el) => el.Phase === phase.Phase)
														.map((p) => (
															<TableRow>
																<TableCell>
																	<span className="text-white text-base font-light">{p.Objective}</span>
																</TableCell>
																<TableCell>
																	<span className="text-white text-base font-light">{p.Input}</span>
																</TableCell>
															</TableRow>
														))
												: prompt
														.filter((el) => el.Phase === phase.Phase)
														.map((p) => (
															<TableRow>
																<TableCell>
																	<span className="text-white text-base font-light">{p.Objective}</span>
																</TableCell>
																<TableCell>
																	<span className="text-white text-base font-light">{p.Input}</span>
																</TableCell>
															</TableRow>
														))}
										</Table>
									</AccordionDetails>
								</Accordion>
							</Grid>
						))}
					</Grid>
				);
			case 2:
				return result.length > 0 ? (
					<TableContainer
						component={Paper}
						sx={{
							backgroundColor: "transparent",
							borderWidth: 1,
							borderColor: "whitesmoke",
							color: "white",
						}}
					>
						<Table sx={{ tableLayout: "fixed" }} aria-label="simple table" ref={tableRef}>
							<TableHead>
								<TableRow>
									<TableCell>
										<span className="text-white text-sm sm:text-base md:text-lg font-light">Objective</span>
									</TableCell>
									{rank !== 0 ? ( // Only render this cell if rank is not 0
										<TableCell>
											<span className="text-white text-sm sm:text-base md:text-lg font-light">Final prompt</span>
										</TableCell>
									) : (
										<TableCell></TableCell>
									)}
									<TableCell>
										<span className="text-white text-sm sm:text-base md:text-lg font-light">Output</span>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell>
										<span className="text-white text-base font-light">{result[0].Objective}</span>
									</TableCell>
									{rank !== 0 && ( // Render the final prompt only if rank is not 0
										<TableCell>
											<span className="text-white text-base font-light">{result[0].finalPrompt}</span>
										</TableCell>
									)}
									<TableCell colSpan={rank === 0 ? 2 : 1}>
										{" "}
										{/* Adjust colSpan based on rank */}
										<textarea
											className="input-areatext"
											rows={25}
											value={result[0].Response}
											onChange={(e) => {
												let r = [...result];
												r[0].Response = e.target.value;
												setResult(r);

												let p = [...prompt];
												p[0].Result = e.target.value;
												setPrompt(p);
											}}
											disabled={true}
										/>
									</TableCell>
								</TableRow>
								<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
									<TableCell style={{ whiteSpace: "pre-wrap" }}>
										<span className="text-white text-base font-light">Competitor Overview</span>
									</TableCell>

									<TableCell style={{ whiteSpace: "pre-wrap" }}>
										<span className="text-white text-base font-light">
											Vuoi chiedere all'AI come mai manca un competitor? Inseriscilo nell'area qui a destra. Lascialo vuoto per proseguire con il flusso senza aggiunte.
										</span>
									</TableCell>
									<TableCell style={{ whiteSpace: "pre-wrap" }}>
										<input
											placeholder="Inserisci Aggiunta"
											className="input-text flex items-center h-fit"
											value={aggiunte}
											onChange={(e) => {
												setAggiunte(e.target.value);
											}}
										/>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				) : (
					<CircularProgress></CircularProgress>
				);
			case 3:
				return resultFinale.length > 0 ? (
					<TableContainer component={Paper}>
						<Table
							aria-label="simple table"
							ref={tableRef}
							sx={{
								backgroundColor: "transparent",
								borderWidth: 1,
								borderColor: "whitesmoke",
								color: "white",
							}}
						>
							<TableHead>
								<TableRow>
									<TableCell>
										<span className="text-white text-sm sm:text-base md:text-lg font-light">Objective</span>
									</TableCell>
									<TableCell>
										<span className="text-white text-sm sm:text-base md:text-lg font-light">Final prompt</span>
									</TableCell>
									<TableCell>
										<span className="text-white text-sm sm:text-base md:text-lg font-light">Output</span>
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{resultFinale.map((row) => (
									<TableRow key={row.idPrompt} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
										<TableCell>{row.Objective}</TableCell>
										<TableCell style={{ whiteSpace: "pre-wrap" }}>
											<span className="text-white text-base font-light">{row.finalPrompt}</span>
										</TableCell>
										<TableCell style={{ whiteSpace: "pre-wrap" }}>
											<span className="text-white text-base font-light">{row.Response}</span>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				) : (
					<CircularProgress />
				);
			default: {
				return <></>;
			}
		}
	};

	return (
		<>
			<div className="w-full py-6 px-4 bg-white/20 shadow-lg ring-1 ring-black/5 backdrop-blur-lg mt-4 ">
				<Stepper nonLinear activeStep={activeStep}>
					{steps.map((label, index) => (
						<Step key={label} completed={completed[index]}>
							<StepButton onClick={handleStep(index)}>
								<span className="text-white text-sm sm:text-base md:text-lg font-medium">{label}</span>
							</StepButton>
						</Step>
					))}
				</Stepper>
				<Paper
					elevation={3}
					sx={{
						padding: "10px",
						marginTop: "10px",
						backgroundColor: "transparent",
						color: "white",
						fontSize: 24,
						boxShadow: "none",
					}}
				>
					{allStepsCompleted() ? (
						<React.Fragment>
							<span className="text-white text-sm sm:text-base md:text-lg font-medium mt-2 mb-1">
								Prompts inviati con successo! Il risultato finale verrà inviato alla tua mail una volta ultimato. Puoi controllare l'avanzamento nell'area dei tuoi{" "}
								<a onClick={() => navigate("/Progetti")} className="cursor-pointer text-main-color underline">
									progetti
								</a>
								.
							</span>
							<div className="flex pt-5 gap-4">
								<div className="flex-1" />
								<button onClick={handleReset} className="button-prophet">
									<FaRepeat color="white" />
									Reset
								</button>
							</div>
						</React.Fragment>
					) : (
						<React.Fragment>
							<div style={{ marginTop: "20px" }}>{renderElement(activeStep)}</div>
							<div className="flex pt-5 gap-4">
								<button
									disabled={activeStep === 0}
									onClick={handleBack}
									className={`w-fit  flex items-center gap-2 text-white font-light text-sm sm:text-sm md:text-lg  transition-all duration-300 focus:ring-4 focus:outline-none px-2 md:px-5 py-1 md:py-2.5 text-center shadow-md uppercase ${
										activeStep !== 0 ? "hover:bg-dark-blue/60 bg-dark-blue" : "hover:bg-gray-500/60 bg-gray-500"
									}`}
								>
									<FaArrowLeft color="white" />
									INDIETRO
								</button>
								<div className="flex-1" />
								<button onClick={handleNext} className="button-prophet">
									<FaArrowRight color="white" />
									Avanti
								</button>
								{activeStep !== steps.length &&
									(completed[activeStep] ? (
										<Typography variant="caption" sx={{ display: "inline-block" }}>
											Step {activeStep + 1} già completato
										</Typography>
									) : (
										<button onClick={handleComplete} className="button-prophet">
											{completedSteps() === totalSteps() - 1 ? <FaFileExport color="white" /> : <FaCheck color="white" />}
											{completedSteps() === totalSteps() - 1 ? "Genera File" : "Completa Passaggio"}
										</button>
									))}
							</div>
						</React.Fragment>
					)}
				</Paper>
			</div>
		</>
	);
};

export default Marketing;
