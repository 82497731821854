import axios from "axios";

export default axios.create({
	//baseURL: "http://localhost:3000/api/",
	baseURL: "https://backend-prophetai-nwhsg4umjq-ey.a.run.app/api/",
	withCredentials: true,
	headers: {
		"Content-type": "application/json",
	},
});
