import "./App.css";
import { Routes, Route, BrowserRouter, useNavigate } from "react-router-dom";
import AppContent from "./components/AppContent";
import Login from "./components/Login";
import Automation from "./components/Automation";
import Prompts from "./components/Prompts";
import PrivateRoute from "./components/PrivateRoute";
import Marketing from "./components/Marketing";
import Progetti from "./components/Progetti";
import DettaglioProgetto from "./components/DettaglioProgetto";
import MillerVisio from "./components/MillerVisio";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import Homepage from "./components/Homepage";
import MillerImageGen from "./components/MillerImageGen";
import MillerGoogleImageGen from "./components/MillerGoogleImageGen";
import MillerFileGenius from "./components/MillerFileGenius";
import Collega from "./components/Collega";
import "react-toastify/dist/ReactToastify.css";
import { BgEffect } from "./components/utility/BgEffect";
import Profile from "./components/Profile";
import Documento from "./components/Documenti";
import DettaglioDocumento from "./components/DettaglioDocumento";
import { Fasi } from "./components/Fasi";

LicenseInfo.setLicenseKey("5b30fac79aff89e43e91021018cca43dTz04MTU1MyxFPTE3MzU5OTY0NDYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

const NotFoundPage = () => {
	const navigate = useNavigate();
	return (
		<div className="h-screen flex w-full items-center justify-center">
			<BgEffect />
			<div className=" w-full md:mt-0 md:max-w-lg bg-white/20 shadow-lg ring-1 ring-black/5 backdrop-blur-sm px-4 py-6 md:px-8 md:py-8 space-y-4 md:space-y-8 ">
				<div className="h-4/5 md:h-fit flex flex-wrap items-center text-center text-white justify-center space-y-4">
					<h1 className="text-3xl md:text-7xl font-semibold w-full">404</h1>
					<h3 className="text-xl md:text-2xl font-normal">PAGINA NON TROVATA</h3>
				</div>
				<button type="submit" onClick={() => navigate("/Homepage")} className="button-login">
					HOMEPAGE
				</button>
			</div>
		</div>
	);
};

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					path="/login"
					element={
						<>
							{
								//#region Background effect
							}
							<BgEffect
								dimentions={{
									width: window.innerWidth,
									height: window.innerHeight,
								}}
							/>
							{
								//#endregion
							}
							<Login />
						</>
					}
				/>

				<Route
					path="/"
					element={
						<PrivateRoute>
							<AppContent />
						</PrivateRoute>
					}
				>
					<Route
						path="/Homepage"
						element={
							<PrivateRoute>
								<Homepage />
							</PrivateRoute>
						}
					/>
					<Route
						path="/Progetto"
						element={
							<PrivateRoute>
								<Automation />
							</PrivateRoute>
						}
					/>
					<Route
						path="/Progetti"
						element={
							<PrivateRoute>
								<Progetti />
							</PrivateRoute>
						}
					/>
					<Route
						path="/Jobs"
						element={
							<PrivateRoute>
								<Collega />{" "}
							</PrivateRoute>
						}
					/>
					<Route
						path="/Marketing"
						element={
							<PrivateRoute>
								<Marketing />{" "}
							</PrivateRoute>
						}
					/>
					{/* <Route
						path="/MillerFileGenius"
						element={
							<PrivateRoute>
								<MillerFileGenius />
							</PrivateRoute>
						}
					/>
					<Route
						path="/Prompts"
						element={
							<PrivateRoute>
								<Prompts />{" "}
							</PrivateRoute>
						}
					/>
					<Route
						path="/MillerImageGen"
						element={
							<PrivateRoute>
								<MillerImageGen />{" "}
							</PrivateRoute>
						}
					/>
					<Route
						path="/MillerGoogleImageGen"
						element={
							<PrivateRoute>
								<MillerGoogleImageGen />{" "}
							</PrivateRoute>
						}
					/>
					
					<Route
						path="/MillerVisio"
						element={
							<PrivateRoute>
								<MillerVisio />{" "}
							</PrivateRoute>
						}
					/>
					
					<Route
						path="/Documenti"
						element={
							<PrivateRoute>
								<Documento />{" "}
							</PrivateRoute>
						}
					/>
					<Route
						path="/Fasi"
						element={
							<PrivateRoute>
								<Fasi />{" "}
							</PrivateRoute>
						}
					/>
					<Route
						path="/MillerVisio"
						element={
							<PrivateRoute>
								<MillerVisio />{" "}
							</PrivateRoute>
						}
					/> */}
					<Route
						path="/DettaglioDocumento"
						element={
							<PrivateRoute>
								<DettaglioDocumento />{" "}
							</PrivateRoute>
						}
					/>
					<Route
						path="/DettaglioProgetto"
						element={
							<PrivateRoute>
								<DettaglioProgetto />{" "}
							</PrivateRoute>
						}
					/>
					<Route
						path="/Profile"
						element={
							<PrivateRoute>
								<Profile />{" "}
							</PrivateRoute>
						}
					/>
				</Route>
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
