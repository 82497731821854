//import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaLeaf } from "react-icons/fa";
import logoC from "../assets/logo.png";
import api from "./api/api";

const Login = () => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const navigate = useNavigate();

	const handleLogin = async (e) => {
		e.preventDefault();

		try {
			let result = await api.post("/login", {
				username: username,
				password: password,
			});
			sessionStorage.setItem("logged", "ok");
			sessionStorage.setItem("username", username);
			sessionStorage.setItem("email", result.data.email);
			sessionStorage.setItem("nome", result.data.nome + " " + result.data.cognome);
			sessionStorage.setItem("rank", result.data.rank);
			navigate("/Homepage");
		} catch (e) {
			if (e.response.data.message === "User Not found.") {
				alert("Utente non trovato");
			}

			if (e.response.data.message === "Invalid Password!") {
				alert("Password errata");
			}
		}
	};

	return (
		<>
			<div className="flex w-full h-screen flex-nowrap bg-transparent relative">
				<div className="flex justify-center align-middle bg-cover bg-center w-full h-[calc(100vh - 4rem)]">
					<div className=" flex flex-col items-center justify-center md:px-6 mx-auto w-full md:h-screen py-0">
						<div className="flex w-full h-fit items-end justify-center">
							<div className=" w-full md:mt-0 md:max-w-lg bg-white/20 shadow-lg ring-1 ring-black/5 backdrop-blur-sm px-4 py-6 md:px-8 md:py-8 space-y-4 md:space-y-6 ">
								<div className="h-4/5 md:h-fit flex items-center text-center text-3xl md:text-4xl font-semibold text-white justify-center ">
									Prophet AI
									<img className="w-12 h-12 ml-2" src={logoC} alt="logo" />
								</div>
								<form autoComplete="nope" className="space-y-4 md:space-y-6" onSubmit={handleLogin}>
									<div>
										<label htmlFor="email" className="label-text">
											Username
										</label>
										<input
											type="text"
											name="username"
											id="username"
											value={username}
											className="input-text-login"
											placeholder="Inserisci l'username"
											required=""
											onChange={(e) => setUsername(e.target.value)}
										/>
									</div>
									<div>
										<label htmlFor="password" className="label-text">
											Password
										</label>
										<input
											type="password"
											name="password"
											id="password"
											placeholder="Inserisci la password"
											value={password}
											className="input-text-login"
											required=""
											onChange={(e) => setPassword(e.target.value)}
										/>
									</div>
									<button type="submit" onClick={handleLogin} className="button-login">
										Login
									</button>
								</form>
							</div>
						</div>
					</div>
				</div>
				<div className="w-full text-center mr-2 text-sm md:text-base absolute bottom-5 -translate-x-[50%] left-[50%] text-white font-semibold">
					Prophet AI è alimentato da server a basso impatto di CO2
					<FaLeaf style={{ display: "inline-block" }} color="green" className="mb-2 ml-2 sm:text-2xl text-xl" />
				</div>
			</div>
		</>
	);
};

export default Login;
