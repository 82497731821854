import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import api from "./api/api";
import { useNavigate } from "react-router-dom";
import { DataGridPro, itIT, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import { CheckBox, Circle, Search } from "@mui/icons-material";
import * as XLSX from "xlsx";
import { FaClipboardList, FaFileImport, FaFileMedical, FaPlus, FaRegTrashAlt, FaSave, FaSearch, FaSearchPlus, FaTrash } from "react-icons/fa";
import { MdContentPaste, MdSearch } from "react-icons/md";
import { toast } from "react-toastify";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Tooltip, Grid } from "@mui/material";
import { Modal } from "./utility/Modal";
import { CustomNoRowsOverlay } from "./utility/NoRowsDataGrid";
import { TabPanel } from "./utility/TabPanel";
import { BsBarChartSteps } from "react-icons/bs";
import CustomAutocomplete from "./utility/Autocomplete";
import { AiOutlineConsoleSql } from "react-icons/ai";

function CustomToolbar() {
	return (
		<GridToolbarContainer>
			<Grid container direction="row" alignItems="center" justifyContent="flex-start" sx={{ columnGap: 2, margin: { xs: 2, md: 1 } }}>
				<GridToolbarQuickFilter
					placeholder="Cerca..."
					sx={{
						"& .MuiInputBase-input": {
							color: "white",
						},
						"& .MuiInputBase-root": {
							color: "white",
						},
					}}
				/>
			</Grid>
		</GridToolbarContainer>
	);
}

const columnsField = [
	{
		field: "Field",
		headerName: "Field",
		flex: 1,
		align: "center",
		headerAlign: "center",
	},
];
const columnsPhase = [
	{
		field: "descrizione",
		headerName: "Phase",
		flex: 1,
		align: "center",
		headerAlign: "center",
	},
];

const columnsPromptOld = [
	{
		field: "Phase",
		headerName: "Phase",
		flex: 1,
		align: "center",
		headerAlign: "center",
	},
	{
		field: "Objective",
		headerName: "Objective",
		flex: 1,
		align: "center",
		headerAlign: "center",
	},
	{
		field: "Input",
		headerName: "Input",
		width: 100,
		align: "center",
		headerAlign: "center",
		renderCell: (params) => {
			return (
				<Tooltip className="cursor-pointer " title={<div className="text-sm">{params.row.Input}</div>}>
					<div className="flex text-white">
						<FaSearchPlus className="text-2xl text-main-color" />
					</div>
				</Tooltip>
			);
		},
	},
	{
		field: "Ordine",
		headerName: "Ordine",
		width: 100,
		align: "center",
		headerAlign: "center",
	},
];
const columnsPrompt = [
	{
		field: "descrizione",
		headerName: "Phase",
		flex: 1,
		align: "center",
		headerAlign: "center",
	},
	{
		field: "Objective",
		headerName: "Objective",
		flex: 1,
		align: "center",
		headerAlign: "center",
	},
	{
		field: "Input",
		headerName: "Input",
		width: 100,
		align: "center",
		headerAlign: "center",
		renderCell: (params) => {
			return (
				<Tooltip className="cursor-pointer " title={<div className="text-sm">{params.row.Input}</div>}>
					<div className="flex text-white">
						<FaSearchPlus className="text-2xl text-main-color" />
					</div>
				</Tooltip>
			);
		},
	},
	{
		field: "Ordine",
		headerName: "Ordine",
		width: 100,
		align: "center",
		headerAlign: "center",
	},
];
const DettaglioDocumento = () => {
	const location = useLocation();
	const [fileds, setFields] = useState([]);
	const [phases, setPhases] = useState([]);
	const [prompts, setPrompts] = useState([]);
	const [nomeDocumento, setNomeDocumento] = useState("");
	const [newDocument, setNewDocument] = useState(true);

	const [modify, setModify] = useState(false);

	const [openPhase, setOpenPhase] = useState(false);
	const [selPhase, setSelPhase] = useState(null);
	const [selectedPhaseIds, setSelectedPhaseIds] = useState(null);

	const [openField, setOpenField] = useState(false);
	const [selField, setSelField] = useState(null);
	const [selectedFieldIds, setSelectedFieldIds] = useState(null);

	const [openPrompt, setOpenPrompt] = useState(false);
	const [selPrompt, setSelPrompt] = useState(null);
	const [selectedPromptIds, setSelectedPromptIds] = useState(null);

	const [tab, setTab] = useState(1);
	const tabs = [
		{
			icon: <FaClipboardList className="text-slate-200 text-lg sm:text-xl md:text-2xl" />,
			text: "Fields",
			id: 1,
		},
		{
			icon: <MdContentPaste className="text-slate-200 text-lg sm:text-xl md:text-2xl" />,
			text: "Prompts",
			id: 2,
		},
		{
			icon: <BsBarChartSteps className="text-slate-200 text-lg sm:text-xl md:text-2xl" />,
			text: "Phases",
			id: 3,
		},
	];

	useEffect(() => {
		if (!location.state.new) {
			loadDettaglioDocumento(location.state.id);
			setNewDocument(false);
		}
	}, []);

	const loadDettaglioDocumento = async (id) => {
		let res = await api.get(`documents/${id}`);
		setPrompts(res.data.obj?.prompts);
		setFields(res.data.obj?.fields);
		setPhases(res.data.obj?.phases);
		setNomeDocumento(location.state.nomeDocumento);
	};

	const handleSave = async () => {
		if (nomeDocumento === "") {
			toast.error("Inserire il nome del documento!");
		} else if (location.state.new) {
			try {
				let res = await api.post("/documents", {
					nome: nomeDocumento,
				});
				setNewDocument(false);
				toast.success("Documento creato con successo!");
			} catch (error) {
				toast.error(error);
			}
		} else {
			try {
				await api.put("/documents", {
					document: { id: location.state.id, Tipologia: nomeDocumento },
				});
				toast.success("Documento modificato con successo!");
			} catch (error) {
				toast.error(error);
			}
		}
	};

	const handleDeleteFields = async () => {
		try {
			let delitingDocument = await api.put("documents/delete/fields", {
				ids: [...selectedFieldIds],
			});
			toast.success(delitingDocument.data.message);
			loadDettaglioDocumento(location.state.id);
		} catch (error) {
			toast.error(error);
		}
	};

	const handleDeletePrompts = async () => {
		try {
			let delitingDocument = await api.put("documents/delete/prompts", {
				ids: [...selectedPromptIds],
			});
			toast.success(delitingDocument.data.message);
			loadDettaglioDocumento(location.state.id);
		} catch (error) {
			toast.error(error);
		}
	};

	const handleDeletePhases = async () => {
		try {
			let delitingDocument = await api.put("documents/delete/phases", {
				ids: [...selectedPhaseIds],
			});
			toast.success(delitingDocument.data.message);
			loadDettaglioDocumento(location.state.id);
		} catch (error) {
			toast.error(error);
		}
	};

	return (
		<>
			<Fields
				open={openField}
				handleClose={() => {
					setOpenField(false);
					setModify(false);
					setSelField(null);
				}}
				id={location.state.id}
				reloadData={() => loadDettaglioDocumento(location.state.id)}
				filed={selField}
				modify={modify}
			/>
			<Prompts
				open={openPrompt}
				handleClose={() => {
					setOpenPrompt(false);
					setModify(false);
					setSelPrompt(null);
				}}
				id={location.state.id}
				reloadData={() => loadDettaglioDocumento(location.state.id)}
				promptSel={selPrompt}
				modify={modify}
				phases={phases}
				max={prompts.length}
			/>

			<Phases
				open={openPhase}
				handleClose={() => {
					setOpenPhase(false);
					setModify(false);
					setSelPhase(null);
				}}
				id={location.state.id}
				reloadData={() => loadDettaglioDocumento(location.state.id)}
				phase={selPhase}
				existingPhases={phases}
				modify={modify}
			/>
			<div className="flex flex-wrap w-full mt-8 gap-4">
				<div className="w-full gap-2">
					<label for="text" className="label-text">
						Tipologia Documento
					</label>
					<div className="w-fit">
						<input type="text" className="input-text" placeholder="Tipologia Documento" value={nomeDocumento} onChange={(e) => setNomeDocumento(e.target.value)} />
					</div>
				</div>
				<div className="w-full flex justify-end">
					<button type="submit" onClick={handleSave} className="button-prophet">
						<FaSave color="white" />
						Salva
					</button>
				</div>

				<div className={`flex flex-wrap w-full gap-4 duration-500 ${newDocument ? "opacity-0" : "opacity-100"} `}>
					<TabPanel
						tab={tab}
						setTab={setTab}
						tabs={tabs}
						content={[
							{
								id: 1,
								child: (
									<>
										<div className="w-full flex items-center justify-between gap-2 mb-4">
											<div className="col-span-2 label-text-xl"></div>
											<div className="flex gap-2">
												<button type="submit" onClick={handleDeleteFields} className={`button-prophet-delete transition-opacity ${selectedFieldIds?.length > 0 ? "opacity-100" : "opacity-0"}`}>
													<FaRegTrashAlt color="white" />
													Elimina
												</button>
												<button type="button" onClick={() => setOpenField(true)} className="button-prophet">
													<FaPlus color="white" />
													Aggiungi
												</button>
											</div>
										</div>
										<div className="w-full">
											<div className="w-full h-[50vh] 2xl:h-[75vh] bg-white/20 shadow-lg ring-1 ring-black/5 backdrop-blur-md rounded-md ">
												<DataGridPro
													localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
													rows={fileds}
													slots={{
														noRowsOverlay: CustomNoRowsOverlay,
														toolbar: CustomToolbar,
													}}
													columns={columnsField}
													initialState={{
														pagination: {
															paginationModel: {
																pageSize: 5,
															},
														},
													}}
													onRowDoubleClick={(row) => {
														setSelField(row.row);
														setModify(true);
														setOpenField(true);
													}}
													onRowSelectionModelChange={(ids) => {
														setSelectedFieldIds(ids);
													}}
													checkboxSelection
													disableRowSelectionOnClick
													getRowId={(r) => r.id}
												/>
											</div>
										</div>
									</>
								),
							},
							{
								id: 2,
								child: (
									<>
										<div className="w-full flex items-center justify-between gap-2 mb-4">
											<div className="col-span-2 label-text-xl"></div>
											<div className="flex gap-2">
												<button type="submit" onClick={handleDeletePrompts} className={`button-prophet-delete transition-opacity ${selectedPromptIds?.length > 0 ? "opacity-100" : "opacity-0"}`}>
													<FaRegTrashAlt color="white" />
													Elimina
												</button>
												<button type="submit" onClick={() => setOpenPrompt(true)} className="button-prophet">
													<FaPlus color="white" />
													Aggiungi
												</button>
											</div>
										</div>
										<div className="w-full">
											<div className="w-full h-[50vh] 2xl:h-[75vh] bg-white/20 shadow-lg ring-1 ring-black/5 backdrop-blur-md rounded-md ">
												<DataGridPro
													localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
													rows={prompts}
													columns={location.state.id === 1 ? columnsPromptOld : columnsPrompt}
													slots={{
														noRowsOverlay: CustomNoRowsOverlay,
														toolbar: CustomToolbar,
													}}
													initialState={{
														pagination: {
															paginationModel: {
																pageSize: 5,
															},
														},
													}}
													onRowDoubleClick={(row) => {
														setSelPrompt(row.row);
														setModify(true);
														setOpenPrompt(true);
													}}
													onRowSelectionModelChange={(ids) => {
														setSelectedPromptIds(ids);
													}}
													checkboxSelection
													disableRowSelectionOnClick
													getRowId={(r) => r.id}
												/>
											</div>
										</div>
									</>
								),
							},
							{
								id: 3,
								child: (
									<>
										<div className="w-full flex items-center justify-between gap-2 mb-4">
											<div className="col-span-2 label-text-xl"></div>
											<div className="flex gap-2">
												<button type="submit" onClick={handleDeletePhases} className={`button-prophet-delete transition-opacity ${selectedPhaseIds?.length > 0 ? "opacity-100" : "opacity-0"}`}>
													<FaRegTrashAlt color="white" />
													Elimina
												</button>
												<button type="submit" onClick={() => setOpenPhase(true)} className="button-prophet">
													<FaPlus color="white" />
													Aggiungi
												</button>
											</div>
										</div>
										<div className="w-full">
											<div className="w-full h-[50vh] 2xl:h-[75vh] bg-white/20 shadow-lg ring-1 ring-black/5 backdrop-blur-md rounded-md ">
												<DataGridPro
													localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
													rows={phases}
													slots={{
														noRowsOverlay: CustomNoRowsOverlay,
														toolbar: CustomToolbar,
													}}
													columns={columnsPhase}
													initialState={{
														pagination: {
															paginationModel: {
																pageSize: 5,
															},
														},
													}}
													onRowDoubleClick={(row) => {
														setSelPhase(row.row);
														setModify(true);
														setOpenPhase(true);
													}}
													onRowSelectionModelChange={(ids) => {
														setSelectedPhaseIds(ids);
													}}
													checkboxSelection
													disableRowSelectionOnClick
													getRowId={(r) => r.id}
												/>
											</div>
										</div>
									</>
								),
							},
						]}
					/>
				</div>
			</div>
		</>
	);
};

export default DettaglioDocumento;

export const Fields = ({ open, handleClose, id, reloadData, filed, modify }) => {
	const [fields, setFields] = useState([""]);

	const handleSave = async () => {
		if (modify) {
			try {
				await api.put("/documents/field", {
					field: { ...filed, Field: fields[0] },
				});
				toast.success("Filed aggiornato con successo!");
				reloadData();
			} catch (error) {
				toast.error(error);
			}
		} else {
			try {
				await api.post("/documents/fields", {
					fields: { idDocumento: id, fields: [...fields] },
				});
				toast.success("Filed creato con successo!");
				reloadData();
			} catch (error) {
				toast.error(error);
			}
		}
		setFields([""]);
		handleClose();
	};

	useEffect(() => {
		(() => {
			if (filed?.Field) {
				setFields([filed?.Field]);
			}
		})();
	}, [filed]);

	return (
		<>
			<Modal
				open={open}
				handleClose={() => {
					handleClose();
					setFields([""]);
				}}
			>
				<div className="w-full">
					<label for="text" className="label-text">
						Fields:
					</label>
					<div className="w-full flex flex-wrap gap-2">
						{fields?.map((field, index) => {
							return (
								<div className="flex w-full gap-2" key={index}>
									<input
										type="text"
										className="input-text"
										placeholder="Inserisci Field"
										value={field}
										onChange={(e) => {
											let aa = [...fields];
											aa[index] = e.target.value;
											setFields([...aa]);
										}}
									/>
									{index === fields?.length - 1 ? (
										<>
											{!modify && (
												<button
													type="button"
													onClick={() => {
														//console.log(index);
														//console.log(fields[index]);
														if (fields[index] !== "") {
															setFields([...fields, ""]);
														}
													}}
													className="button-prophet-reverse "
												>
													<FaPlus className="text-dark-blue" />
												</button>
											)}
										</>
									) : (
										<button
											type="button"
											onClick={() => {
												setFields(fields.filter((e) => e !== field));
											}}
											className="button-prophet-reverse "
										>
											<FaRegTrashAlt className="text-dark-pink" />
										</button>
									)}
								</div>
							);
						})}
					</div>
				</div>
				<div className="w-full flex justify-end mt-4">
					<button type="button" onClick={() => handleSave()} className="button-prophet-reverse ">
						<FaSave className="text-dark-blue" />
						Salva
					</button>
				</div>
			</Modal>
		</>
	);
};

const Prompts = ({ open, handleClose, id, reloadData, promptSel, modify, phases, max }) => {
	const [prompt, setPrompt] = useState({
		DocumentType: id,
		Phase: "",
		Objective: "",
		Input: "",
		Ordine: 0,
	});
	const [error, setError] = useState(false);

	const handleSave = async () => {
		if (prompt?.Phase === "" || prompt?.Objective === "" || prompt?.Input === "") {
			setError(true);
			toast.error("Campi obbligatori!");
		} else {
			if (modify) {
				//console.log(prompt);
				try {
					await api.put("/documents/prompt", {
						prompt: prompt,
					});
					toast.success("Prompt aggiornato con successo!");
					reloadData();
				} catch (error) {
					toast.error(error);
				}
			} else {
				try {
					await api.post("/documents/prompt", {
						prompt: prompt,
					});
					toast.success("Prompt creato con successo!");
					reloadData();
				} catch (error) {
					toast.error(error);
				}
			}
			setPrompt({
				DocumentType: id,
				Phase: "",
				Objective: "",
				Input: "",
				Ordine: 0,
			});
			handleClose();
		}
	};

	useEffect(() => {
		if (promptSel)
			return setPrompt({
				...promptSel,
				Phase: promptSel?.DocumentType === 1 ? promptSel?.Phase : Number(promptSel?.Phase),
			});
	}, [promptSel]);

	return (
		<>
			<Modal
				open={open}
				handleClose={() => {
					setPrompt({
						DocumentType: id,
						Phase: "",
						Objective: "",
						Input: "",
						Ordine: 0,
					});
					handleClose();
					setError(false);
				}}
			>
				<div className="w-full">
					<div className="flex flex-wrap w-full">
						{id !== 1 ? (
							<>
								<CustomAutocomplete
									placeholder="Seleziona Phase"
									suggestions={phases}
									fields={["descrizione"]}
									keyField="id"
									value={Number(prompt?.Phase)}
									id="descrizione"
									label="Phase"
									name="descrizione"
									error={error && prompt?.Phase === "" ? true : false}
									onChange={(value) => {
										setPrompt({ ...prompt, Phase: value });
									}}
								/>
							</>
						) : (
							<>
								<label for="text" className="label-text">
									Phase
								</label>
								<input
									type="text"
									className={error && prompt?.Phase === "" ? "input-text-error" : "input-text"}
									placeholder="Inserisci Phase"
									value={prompt.Phase}
									onChange={(e) => {
										setPrompt({ ...prompt, Phase: e.target.value });
									}}
								/>
							</>
						)}
						<label for="text" className="label-text">
							Objective
						</label>
						<input
							type="text"
							className={error && prompt?.Objective === "" ? "input-text-error" : "input-text"}
							placeholder="Inserisci Objective"
							value={prompt.Objective}
							onChange={(e) => {
								setPrompt({ ...prompt, Objective: e.target.value });
							}}
						/>
						<label for="text" className="label-text">
							Input
						</label>
						<textarea
							rows={5}
							className={error && prompt?.Input === "" ? "input-areatext-error" : "input-areatext"}
							placeholder="Inserisci Input"
							value={prompt.Input}
							onChange={(e) => {
								setPrompt({ ...prompt, Input: e.target.value });
							}}
						/>
						<label for="text" className="label-text">
							Ordine
						</label>
						<input
							type="number"
							className="input-text"
							placeholder="Inserisci Ordine"
							value={prompt.Ordine}
							min={"1"}
							max={promptSel === null ? max + 1 : max}
							onChange={(e) => {
								setPrompt({ ...prompt, Ordine: e.target.value });
							}}
						/>
					</div>
				</div>
				<div className="w-full flex justify-end mt-4">
					<button type="button" onClick={handleSave} className="button-prophet-reverse ">
						<FaSave className="text-dark-blue" />
						Salva
					</button>
				</div>
			</Modal>
		</>
	);
};

const Phases = ({ open, handleClose, id, reloadData, phase, modify, existingPhases }) => {
	const [phases, setPhases] = useState([""]);
	const [phasesError, setPhasesError] = useState([]);

	const handleSave = async () => {
		//creo un array con tutti i nomi delle phases
		const descPhases = existingPhases?.map((phase) => phase?.descrizione);
		//cerco i duplicati nelle phases già esistenti
		const duplicates = descPhases.map((p) => p.toUpperCase()).filter((phase) => phases.map((a) => a.toUpperCase()).includes(phase));
		//cerco i duplicati nelle phases appena aggiunte
		const inputDuplicates = phases.map((a) => a.toUpperCase()).filter((item, index) => phases.some((elem, idx) => elem.toUpperCase() === item && idx !== index));

		if (inputDuplicates?.length > 0) {
			toast.error("Phases duplicate!");
			setPhasesError(inputDuplicates.map((p) => p.toUpperCase()));
		} else if (duplicates?.length > 0) {
			toast.error("Phase già presente!");
			setPhasesError(duplicates);
		} else if (phases.includes("")) {
			toast.error("Phases vuota!");
			setPhasesError([""]);
		} else {
			if (modify) {
				try {
					await api.put("/documents/phase", {
						phase: { ...phase, descrizione: phases[0] },
					});
					toast.success("Phase aggiornato con successo!");
					reloadData();
				} catch (error) {
					toast.error(error);
				}
			} else {
				try {
					await api.post("/documents/phases", {
						phases: { idDocumento: id, phases: [...phases] },
					});
					toast.success("Phases creato con successo!");
					reloadData();
				} catch (error) {
					toast.error(error);
				}
			}
			handleClose();
			setPhasesError([]);
			setPhases([""]);
		}
	};

	useEffect(() => {
		(() => {
			if (phase?.descrizione) {
				//console.log([phase?.descrizione]);
				setPhases([phase?.descrizione]);
			}
		})();
	}, [phase]);

	return (
		<>
			<Modal
				open={open}
				handleClose={() => {
					handleClose();
					setPhasesError([]);
					setPhases([""]);
				}}
			>
				<div className="w-full">
					<label for="text" className="label-text">
						Phases:
					</label>
					<div className="w-full flex flex-wrap gap-2">
						{phases?.map((phase, index) => {
							return (
								<div className="flex w-full gap-2" key={index}>
									<input
										type="text"
										className={`${phasesError?.includes(phase.toUpperCase()) ? "input-text-error" : "input-text"}`}
										placeholder="Inserisci Phase"
										value={phase}
										onChange={(e) => {
											let aa = [...phases];
											aa[index] = e.target.value;
											setPhases([...aa]);
										}}
									/>
									{index === phases?.length - 1 ? (
										<>
											{!modify && (
												<button
													type="button"
													onClick={() => {
														if (phases[index] !== "") {
															setPhases([...phases, ""]);
														}
													}}
													className="button-prophet-reverse "
												>
													<FaPlus className="text-dark-blue" />
												</button>
											)}
										</>
									) : (
										<button
											type="button"
											onClick={() => {
												setPhases(phases.filter((e) => e !== phase));
											}}
											className="button-prophet-reverse "
										>
											<FaRegTrashAlt className="text-dark-pink" />
										</button>
									)}
								</div>
							);
						})}
					</div>
				</div>
				<div className="w-full flex justify-end mt-4">
					<button type="button" onClick={handleSave} className="button-prophet-reverse ">
						<FaSave className="text-dark-blue" />
						Salva
					</button>
				</div>
			</Modal>
		</>
	);
};
