import { KeyboardArrowDownSharp } from "@mui/icons-material";

import { Outlet } from "react-router-dom";
import { FaFileCode, FaFile, FaFolder, FaHome, FaImage, FaPowerOff, FaUser } from "react-icons/fa";
import { FaNewspaper } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import bgCubic from "../assets/bg-home.jpg";
import { FaBars, FaBarsStaggered, FaMessage } from "react-icons/fa6";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PiStepsFill } from "react-icons/pi";
import { IoMdChatboxes } from "react-icons/io";
import { useState } from "react";

const AppContent = () => {
	const navigate = useNavigate();

	const [open, setOpen] = useState(false);
	const Menus = [
		{
			Title: "Homepage",
			icon: <FaHome size={24} />,
			link: "Homepage",
			multiLevel: false,
		},
		{
			Title: "Progetti",
			icon: <FaFolder size={24} />,
			link: "Progetti",
			multiLevel: false,
		},

		{
			Title: "Jobs",
			icon: <FaMessage size={24} />,
			link: "Jobs",
			multiLevel: false,
		},

		/* {
			Title: "MillerFileGenius",
			icon: <FaFile size={24} />,
			link: "MillerFileGenius",
			multiLevel: false,
		},
		{
			Title: "MillerImageGen",
			icon: <FaImage size={24} />,
			link: "MillerImageGen",
			multiLevel: false,
		},
		{
			Title: "Miller Visio",
			icon: <IoMdChatboxes size={24} />,
			link: "MillerVisio",
			multiLevel: false,
		},

		{
			Title: "Documenti",
			icon: <FaNewspaper size={24} />,
			link: "Documenti",
			multiLevel: false,
		}, */
		{
			Title: "Logout",
			icon: <FaPowerOff size={24} />,
			link: "Login",
			multiLevel: false,
		},

		//{ Title: "Prompts", icon: <SettingsSuggest />,multiLevel:false },
		//mi servere multiLevel per capire se è èun menù con sotto menu
		//{ title: "Accounts", src: "User", gap: true }, //gap true crea spazio in quello sopra
		// { Title: "Documento", icon: <EqualizerSharp />,multiLevel:true , children: [{ Link:"Vendite", Title: "Previsione Vendite", icon: <SellSharp /> }] },
	];

	return (
		<>
			<ToastContainer
				position="top-right"
				autoClose={3000}
				hideProgressBar={true}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
				toastStyle={{ backgroundColor: "#060044" }}
				transition={Slide}
			/>
			<div className="h-screen bg-right lg:bg-center bg-cover" style={{ backgroundImage: `url(${bgCubic})` }}>
				<div className="relative w-full flex h-fit px-4 py-4 bg-white/20 shadow-lg ring-1 ring-black/5 backdrop-blur-lg z-50 ">
					<div className="flex gap-x-2 cursor-pointer items-center duration-300">
						<div className="relative w-[24px] md:w-[30px] h-[24px] md:h-[30px] mx-2 transition-all duration-300" onClick={() => setOpen(!open)}>
							<FaBarsStaggered color="white" className={`absolute top-[50%] -translate-y-[50%] text-2xl md:text-3xl transition-all duration-300 ${open ? "opacity-100" : "opacity-0"}`} />

							<FaBars color="white" className={`absolute top-[50%] -translate-y-[50%]  text-2xl md:text-3xl transition-all duration-300 ${!open ? "opacity-100" : "opacity-0"}`} />
						</div>

						<img className="ml-2" alt="foto" src={require("../assets/logo.png")} onClick={() => navigate("/Homepage")} />
						<h1 className={`text-white origin-left font-medium text-xl`} onClick={() => navigate("/Homepage")}>
							Prophet AI
						</h1>
					</div>
					<div className="flex-1"></div>
					<div onClick={() => navigate("/Profile")} className="flex items-center duration-300 cursor-pointer hover:scale-110">
						<FaUser color="white" className="text-2xl md:text-3xl" />
					</div>
					<div className={` ${open ? " w-72  p-3" : "w-0 md:w-20 "} absolute left-0 top-[72px] h-[calc(100vh_-_72px)] md:p-5 md:pt-8 duration-300 bg-dark-blue backdrop-blur-none`}>
						<ul className={`${!open && "hidden"} md:block `}>
							{Menus.map((Menu, index) => (
								<li key={index} className="group relative">
									<button
										type="button"
										onClick={
											Menu.multiLevel
												? () =>
														document.getElementById("dropdown-" + Menu.Title)?.classList.contains("hidden")
															? document.getElementById("dropdown-" + Menu.Title).classList.remove("hidden")
															: document.getElementById("dropdown-" + Menu.Title).classList.add("hidden")
												: () => {
														navigate("/" + Menu.link);
														setOpen(false);
												  }
										}
										className={`flex w-full p-2 cursor-pointer hover:bg-white/20 hover:scale-105 duration-300 text-gray-300 text-base items-center gap-x-4 
              ${Menu.gap ? "mt-9" : "mt-4"} ${window.location.href.indexOf("/" + Menu.Title) !== -1 && "bg-white/20"} `}
										aria-controls="dropdown-example"
										data-collapse-toggle="dropdown-example"
									>
										{Menu.icon}{" "}
										<span className={`flex-1 ml-3 text-left whitespace-nowrap ${!open && "hidden"} origin-left duration-200`}>
											{Menu.Title}

											{Menu.multiLevel ? <KeyboardArrowDownSharp className={` ${!open && "hidden"} origin-left duration-200`} /> : ""}
										</span>
									</button>
									<span
										className={`opacity-0 -left-40 ${
											open ? "group-hover:opacity-0" : "group-hover:opacity-100 group-hover:left-10"
										}  absolute z-50 w-max -translate-x-[0%] top-[50%] -translate-y-[50%] text-white bg-dark-blue py-1 px-2 shadow-lg text-xs font-light mb-1 duration-300 transition-opacity`}
									>
										{Menu.Title}
									</span>
									{Menu.multiLevel ? (
										<ul id={"dropdown-" + Menu.Title} className={"py-2 space-y-2 " + (window.location.href.indexOf("/" + Menu.Title) !== -1 ? "" : "hidden")}>
											{Menu.children.map((c, index) => (
												<li className={open ? "ml-3" : ""}>
													<button
														type="button"
														onClick={() => navigate("/" + Menu.link + "/" + c.Link)}
														className={`flex w-full  p-2 cursor-pointer hover:bg-white/20 text-gray-300 text-base items-center gap-x-4 ${
															window.location.href.indexOf("/" + Menu.Title + "/" + c.Link) !== -1 && "bg-white"
														}`}
														aria-controls="dropdown-example"
														data-collapse-toggle="dropdown-example"
													>
														{c.icon}
														<span className={`flex-1 ml-3 text-left whitespace-nowrap ${!open && "hidden"} origin-left duration-200`}> {c.Title} </span>
													</button>
												</li>
											))}
										</ul>
									) : (
										""
									)}
								</li>
							))}
						</ul>
					</div>
					<div onClick={() => open && setOpen(!open)} className={`absolute ${open ? "left-72 top-[72px] h-[calc(100vh_-_72px)] w-[calc(100vw_-_288px)] " : ""} `}></div>
				</div>
				<div className={`p-2 md:p-4 md:ml-20 z-0 duration-300 h-[calc(100vh_-_72px)]  overflow-x-hidden overflow-y-auto ${open ? "opacity-50 backdrop-blur-lg" : "opacity-100"}`}>
					<h1 className="text-2xl font-semibold text-white">
						{
							window.location.href.split("/").pop().charAt(0).toUpperCase() + window.location.href.split("/").pop().slice(1)
							/* .toLocaleLowerCase() */
						}
					</h1>
					<div className="">
						<Outlet />
					</div>
				</div>
			</div>
		</>
	);
};

export default AppContent;
